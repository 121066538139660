import { contentTypes } from '@/api/index'
import Contents from '@/views/Contents/Index.vue'
import navMenuItems from '@/navigation/vertical'
import _ from 'lodash'
import router from '@/router/index'

const state = {
  menu: null,
  components: [],
  models: [],
}
const getters = {
  Menu: state => state.menu,
  Components: state => state.components,
  Models: state => state.models,
}

const actions = {
  async LoadModels({ commit }) {
    const models = await contentTypes.getModels()
    const sysmodels = await contentTypes.getModels({ system: true })
    const components = await contentTypes.getComponents()

    commit('setContentTypes', { models: [...models.data.data, ...sysmodels.data.data], components: components.data.data })

    let menu = [
      {
        title: 'Home',
        route: 'Home',
        icon: 'HomeIcon',
      },
    ]

    let routes = []

    const contentReadPermissions = []
    if (state.models.length > 0) {
      menu.push({
        header: 'Contents',
        permissions: contentReadPermissions,
      })
    }

    _.each(
      state.models.filter(m => m.system === false),
      function(t) {
        let permission = `contents.${t.id}.read`
        contentReadPermissions.push(permission)

        if (t.isSingle) {
          routes.push({
            name: 'atlas_single_' + t.key,
            component: Contents,
            path: '/contents/' + t.key,
            meta: { active: true, layout: '', requiresAuth: true, type: t.key, typeId: t.id, isSingle: true, contentRoute: true },
          })

          menu.push({
            title: t.name,
            route: 'atlas_single_' + t.key,
            icon: t.properties.icon ? `${t.properties.icon}Icon` : 'EditIcon',
            meta: { contentRoute: true },
            permissions: [permission],
          })
        } else {
          routes.push({
            name: 'atlas_list_' + t.key,
            component: Contents,
            path: '/contents/' + t.key,
            meta: { active: true, layout: '', requiresAuth: true, type: t.key, typeId: t.id, isList: true, contentRoute: true },
          })

          menu.push({
            title: t.name,
            route: 'atlas_list_' + t.key,
            icon: t.properties.icon ? `${t.properties.icon}Icon` : 'EditIcon',
            meta: { contentRoute: true },
            permissions: [permission],
          })
        }

        routes.push({
          name: 'atlas_new_' + t.key,
          component: Contents,
          path: '/contents/' + t.key + '/new',
          meta: { active: true, layout: '', requiresAuth: true, type: t.key, typeId: t.id, isNew: true, contentRoute: true },
        })

        routes.push({
          name: 'atlas_edit_' + t.key,
          component: Contents,
          path: '/contents/' + t.key + '/:id',
          meta: { active: true, layout: '', requiresAuth: true, type: t.key, typeId: t.id, isEdit: true, contentRoute: true },
        })
      }
    )

    routes.forEach(r => {
      router.addRoute(r)
    })

    menu.push(...navMenuItems)

    commit('setMenu', menu)
  },
}

const mutations = {
  setMenu(state, menu) {
    state.menu = menu
  },
  setContentTypes(state, types) {
    state.components = types.components
    state.models = types.models
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
