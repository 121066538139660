<template>
  <div class="mb-1">
    <b-nav>
      <b-nav-item :active="isActive('SettingsGeneral')">
        <h6><router-link :to="{ name: 'SettingsGeneral' }">General</router-link></h6>
      </b-nav-item>
      <b-nav-item :active="isActive('SettingsAccounts')" v-if="$acl.can('admin.accounts.read')">
        <h6><router-link :to="{ name: 'SettingsAccounts' }">Accounts</router-link></h6>
      </b-nav-item>
      <b-nav-item :active="isActive('SettingsRoles')" v-if="$acl.can('admin.roles.read')">
        <h6><router-link :to="{ name: 'SettingsRoles' }">Roles</router-link></h6>
      </b-nav-item>
      <b-nav-item :active="isActive('SettingsApiKeys')" v-if="$acl.can('admin.apikeys.read')">
        <h6><router-link :to="{ name: 'SettingsApiKeys' }">Api Keys</router-link></h6>
      </b-nav-item>
      <b-nav-item :active="isActive('SettingsWebhooks')" v-if="$acl.can('admin.webhooks.read')">
        <h6><router-link :to="{ name: 'SettingsWebhooks' }">Webhooks</router-link></h6>
      </b-nav-item>
    </b-nav>
  </div>
</template>
<script>
export default {
  props: [],
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {
    isActive(section) {
      return this.$route.name == section
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/variables/_variables.scss';

h6 {
  a {
    color: $secondary;
  }
}

.active h6 {
  a {
    padding-bottom: 0.2rem;
    border-bottom: 2px solid;
    color: $primary !important;
  }
}
</style>
