import store from '@/store'
import _ from 'lodash'

const acl = {
  user() {
    return store.getters.Me
  },
  can(ability) {
    var user = this.user()

    if (user.roles.includes('admins')) return true

    if (user.permissions.includes(ability)) {
      return true
    }

    return false
  },
  canOneOf(abilities) {
    let result = false

    if (!abilities) return true

    if (Array.isArray(abilities)) {
      var user = this.user()

      if (user.roles.includes('admins')) return true

      for (let i = 0, len = abilities.length; i < len; i++) {
        const ability = abilities[i]
        if (ability.endsWith('*')) {
          if (
            _.findIndex(user.permissions, function(o) {
              return o.startsWith(ability.substring(0, ability.length - 1))
            }) >= 0
          ) {
            result = true
          }
        } else {
          if (user.permissions.includes(ability)) {
            result = true
          }
        }

        if (result) break
      }
    }
    return result
  },
  canAllOf(abilities) {
    return false
  },
}

export default acl
