<template>
  <div>
    <b-modal
      v-model="editAccountModalVisible"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="lg"
      @hidden="reset"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      :ok-disabled="!$acl.can('admin.accounts.update')"
    >
      <div class="px-1 pt-1">
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <feather-icon class="text-dark" icon="UserIcon" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            Edit Account
          </h4>
          <b-card-text>
            Edit the account's information
          </b-card-text>
        </b-media>
        <hr class="mt-0 mb-2" />
        <page-loader v-if="loading" type="content" :fluid="true" size="small" />
        <div v-if="!loading">
          <form-alert :errors="serverErrors" />
          <fieldset :disabled="!$acl.can('admin.accounts.update')">
            <validation-observer ref="accountForm">
              <b-form v-on:keyup.enter="onSave" @submit.prevent>
                <b-row>
                  <!-- names -->
                  <b-col cols="6">
                    <text-input v-model="account.firstName" placeholder="First Name" label="First Name" validation-rules="required" />
                  </b-col>

                  <b-col cols="6">
                    <text-input v-model="account.lastName" placeholder="Last Name" label="Last Name" validation-rules="required" />
                  </b-col>

                  <!-- username -->
                  <b-col cols="12">
                    <text-input v-model="account.username" placeholder="Username" label="Username (email)" validation-rules="required|email" type="email" />
                  </b-col>

                  <b-col cols="6">
                    <validation-provider rules="required" name="roles" v-slot="{ errors }" tag="div">
                      <b-form-group label="Roles" label-for="roles" :class="errors.length > 0 ? 'is-invalid' : null">
                        <v-select
                          id="roles"
                          multiple
                          v-model="account.roles"
                          :options="roles"
                          :reduce="role => role.id"
                          label="name"
                          v-if="$acl.can('admin.accounts.update')"
                        />
                        <div v-else>
                          <b-badge v-for="(item, index) in account.roles" :key="index" pill variant="primary">
                            {{ roleName(item) }}
                          </b-badge>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- status -->
                  <b-col cols="12">
                    <b-form-group>
                      <div class="inline-spacing">
                        <b-form-checkbox id="isActive" name="isActive" v-model="account.isActive">
                          Is Active
                        </b-form-checkbox>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </fieldset>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { accounts } from '@/api/index'
import vSelect from 'vue-select'
import DefaultNotifications from '@/components/Notification/default'
import { extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'

extend('confirmpassword', {
  ...confirmed,
  message: 'The passwords do not match',
})

export default {
  name: 'EditAccount',
  components: {
    vSelect,
  },
  props: ['id', 'roles'],
  data() {
    return {
      accountId: 0,
      editAccountModalVisible: false,
      account: {},
      original: {},
      model: null,
      isDirty: false,
      formErrors: false,
      serverErrors: [],
      loading: false,
      confirmDelete: false,
      newPassword: {
        password: '',
        confirm: '',
      },
    }
  },
  watch: {
    account: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.original)
      },
    },
  },
  computed: {
    validAccountRoles() {
      const self = this
      const roles = self.roles.map(r => r.id)
      const validRoles = self.$_.filter(self.user.roles, function(item) {
        return self.$_.includes(roles, item)
      })
      return validRoles
    },
  },
  mounted() {},
  created() {},
  methods: {
    async editAccount(id) {
      this.accountId = id
      await this.fetchData()
      this.editAccountModalVisible = true
    },
    async fetchData() {
      const self = this
      self.loading = true

      accounts.getAccount(self.accountId).then(res => {
        self.original = res.data
        self.account = self.$_.cloneDeep(self.original)
        self.loading = false
      })
    },
    onSetRoles(e) {
      this.account.roles = e
    },
    async onSave(e) {
      e.preventDefault()
      const self = this

      const validation = await self.$refs.accountForm.validateWithInfo()

      if (validation.isValid) {
        accounts
          .updateAccount(self.accountId, self.account)
          .then(res => {
            self.formErrors = false
            self.serverErrors = []
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.editAccountModalVisible = false
            self.$emit('modified')
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.serverErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.formErrors = true
      }
    },
    reset() {
      this.editAccountModalVisible = false
      this.account = {}
    },
    roleName(id) {
      var roles = this.roles.filter(r => r.id == id)

      if (roles && roles.length) {
        return roles[0].name
      }

      return id
    },
  },
}
</script>
