<template>
  <b-container class="ml-auto mb-2" fluid> </b-container>
</template>
<script>
import { contentTypes, contents } from '@/api/index'
export default {
  name: 'SingleContent',
  props: ['model'],
  components: {},
  data() {
    return {
      loading: false,
    }
  },
  mounted() {},
  watch: {},
  computed: {
    type() {
      return this.$route.meta.type
    },
  },
  created() {
    this.fetchData()
  },
  beforeMount() {},
  beforeDestroy() {},
  methods: {
    fetchData() {
      const self = this

      let filters = {}
      if (self.model.enableStageMode) {
        filters.stage = 'all'
      }
      contents
        .getContents(self.type, filters)
        .then(res => {
          self.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: res.data.id } }).catch(() => {})
        })
        .catch(err => {
          if (err.response.status == 404) {
            self.$router.push({ name: 'atlas_new_' + self.model.key }).catch(() => {})
          }
        })
    },
    save() {},
  },
}
</script>
