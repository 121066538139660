<template>
  <pre class="mb-2 border-warning p-1 bg-light" v-if="$route.query.debug">
    <h5 class="m-0 p-0">{{ name }}</h5>
    {{ data }}
  </pre>
</template>
<script>
export default {
  props: ['name', 'data'],
}
</script>
