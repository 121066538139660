import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import models from './models'
import settings from './settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    auth,
    models,
    settings,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState({ key: 'atlas', paths: ['app', 'appConfig', 'auth.user', 'settings'] })],
})
