import http from '@/http/index'

const admin = {
  async getSettings(project) {
    return await http.get(`/admin/settings`)
  },
  async updateSettings(data) {
    return await http.put(`/admin/settings`, data)
  },
}

export default admin

