<template>
  <div>
    <b-form-group>
      <validation-provider #default="{ errors }" :name="field.label" :rules="rules">
        <b-form-input
          :id="field.label"
          size="md"
          :value="internal"
          @input="onInput"
          :state="errors.length > 0 ? false : null"
          :readonly="field.readOnly"
          v-if="field.editor == 'single_line'"
        />
        <b-form-textarea
          :id="field.label"
          size="md"
          :value="internal"
          @input="onInput"
          :state="errors.length > 0 ? false : null"
          :readonly="field.readOnly"
          :rows="10"
          v-if="field.editor == 'textarea'"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { textLengthRange } from '../Validators/FieldValidators'

export default {
  name: 'TextField',
  props: {
    field: {},
    value: {},
    contents: {},
    errors: [],
  },
  data() {
    return {
      internal: '',
      textLengthRange,
    }
  },
  mounted() {
    // extend('length', minChars, { paramNames })
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internal = val
      },
    },
  },
  computed: {
    rules() {
      let ruleSet = []

      if (this.field.required) {
        ruleSet.push('required')
      }

      if (this.field.min > 0 || this.field.max > 0) {
        ruleSet.push(`text_length_range:${this.field.min},${this.field.max}`)
      }

      return ruleSet.join('|')
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event)
    },
  },
}
</script>
