import http from '@/http/index'

const users = {
  async getUsers(query) {
    return await http.get(`/users`, { params: query })
  },
  async getUser(id) {
    return await http.get(`/users/${id}`)
  },
  async createUser(data) {
    return await http.post(`/users/register`, data)
  },
  async updateUser(id, data) {
    return await http.put(`/users/${id}`, data)
  },
  async deleteUser(id) {
    return await http.delete(`/users/${id}`)
  },
  async changePassword(id, data) {
    return await http.post(`/users/${id}/change-password`, data)
  },
  async getRole(id) {
    return await http.get(`/roles/${id}`)
  },
  async getRoles() {
    return await http.get(`/roles`)
  },
  async createRole(data) {
    return await http.post(`/roles`, data)
  },
  async updateRole(id, data) {
    return await http.put(`/roles/${id}`, data)
  },
  async getPermissionSet() {
    return await http.get(`/roles/permissions`)
  },
  async deleteRole(id) {
    return await http.delete(`/roles/${id}`)
  },
}

export default users

