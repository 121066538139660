<template>
  <div>
    <section-divider text="Multi Component Settings" />
    <b-form-group label="Allowed Types *">
      <validation-provider #default="{ errors }" name="Editor" rules="required">
        <v-select id="multicomponents" multiple v-model="item.allowedTypes" :options="componentOptions" size="md" required :reduce="comp => comp.code" />
        <small class="text-danger">{{ errors[0] }}</small>
        <b-form-text>The type of components</b-form-text>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { contentTypes } from '@/api/index'
import vSelect from 'vue-select'
export default {
  name: 'MultipleComponentFieldSettings',
  props: ['value'],
  components: { vSelect },
  data() {
    return {
      components: [],
    }
  },
  computed: {
    componentOptions() {
      return this.components.map(c => {
        return { code: c.id, label: c.name }
      })
    },
    item() {
      return this.value ? this.value : { name: '' }
    },
  },
  created() {
    this.fetchComponents()
  },
  methods: {
    fetchComponents() {
      var self = this
      contentTypes.getComponents().then(res => {
        self.components = res.data.data
      })
    },
    update(key, value) {
      this.$emit('input', { ...this.item, [key]: value })
    },
  },
}
</script>
