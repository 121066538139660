<template>
  <div v-if="localComponent">
    <div v-if="internal != null" class="border p-2">
      <div v-for="componentField in localComponent.attributes" :key="componentField.key" class="mb-0">
        <field-editor
          :value="internal[componentField.key]"
          :field="componentField"
          :nested="true"
          :parent-key="parentKey ? parentKey : field.key"
          :locale="locale"
          :errors="errors"
          :index="index"
          @input="onInput($event, componentField.key)"
          @on-error-status-change="$emit('on-error-status-change', $event)"
        />
        <atlas-debugger name="original value" :data="internal[componentField.key]" />
      </div>
    </div>
    <div v-else class="d-flex py-2">
      <b-button variant="primary" @click="internal = {}" class="mx-auto"><feather-icon icon="PlusIcon" /> Add {{ localComponent.name }}</b-button>
    </div>
  </div>
</template>
<script>
import { contentTypes } from '@/api/index'

export default {
  name: 'ComponentField',
  props: {
    field: {
      type: Object,
      default: null,
    },
    value: {},
    nested: {
      type: Boolean,
      default: false,
    },
    parentKey: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    component: {
      type: Object,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: [],
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      internal: {},
      localComponent: null,
    }
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.internal = val ? val : null
      },
    },
    component: {
      immediate: true,
      deep: true,
      handler(val) {
        this.localComponent = val ? val : null
      },
    },
  },
  mounted() {
    if (this.field && this.field.key) {
      this.localComponent = this.$ContentTypes.findComponent(this.field.allowedType)
    }
  },
  methods: {
    onInput(event, key) {
      const payload = { ...this.value, [key]: event }

      if (!payload.hasOwnProperty('@component')) {
        payload['@component'] = {
          id: this.localComponent.id,
          key: this.localComponent.key,
        }
      }

      this.$emit('input', payload)
    },
  },
}
</script>
