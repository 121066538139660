<template>
  <div>
    <b-button variant="primary" @click.prevent="visible = true"><feather-icon icon="PlusIcon" /> Select Media</b-button>

    <b-modal
      dialog-class="modal-full-screen selector"
      :no-close-on-backdrop="false"
      :no-close-on-esc="false"
      :hide-header="true"
      :hide-footer="true"
      v-model="visible"
      centered
      size="lg"
      ok-variant="outline-secondary"
      ok-only
      ok-title="Done"
      @show="onShow"
      @hidden="onHidden"
    >
      <!-- <div class="media-selector-content-wrapper"> -->
      <!-- <div style="height: inherit"> -->
      <!-- <div class="body-content-overlay" /> -->
      <!-- <div class="d-flex">
        <div class="w-25"> -->
      <library-sidebar @folder-selected="onFolderSelected" class="selector" />
      <!-- </div>
        <div class="w-75"> -->
      <!-- <b-button variant="success" size="lg" v-if="selectedItems.length > 0" @click="onSelectionDone">Add {{ selectedItems.length }} Media</b-button> -->

      <library-area :current-folder="currentFolder" mode="selector" :max-selection="maxSelection" @selected-items-change="onSelectionChange" />
      <!-- </div> -->
      <portal to="media-selector-footer-actions">
        <b-button variant="success" size="lg" v-if="selectedItems.length > 0" @click="onSelectionDone">Add {{ selectedItems.length }} Media</b-button>
      </portal>
      <!-- </div> -->
      <!-- </div> -->
    </b-modal>
  </div>
</template>
<script>
import LibrarySidebar from '@/views/MediaLibrary/components/LibrarySidebar.vue'
import LibraryArea from '@/views/MediaLibrary/components/LibraryArea.vue'

export default {
  name: 'MediaSelector',
  props: ['maxSelection'],
  components: {
    LibrarySidebar,
    LibraryArea,
  },
  data() {
    return {
      currentFolder: null,
      visible: false,
      selectedItems: [],
    }
  },
  created() {},
  methods: {
    onFolderSelected(folder) {
      this.currentFolder = folder
    },
    onSelectionChange(items) {
      this.selectedItems = items
      //this.onHidden()
    },
    onSelectionDone() {
      this.$emit('selection-done', this.selectedItems)
      this.visible = false
    },
    onHidden() {
      this.visible = false
    },
    onShow() {
      this.selectedItems = []
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/media-library/media-library.scss';
@import '@/assets/scss/media-library/media-library-list.scss';
@import '@/assets/scss/media-library/upload.scss';
@import '@/assets/scss/media-library/media-library-media-info.scss';

.media-library-edit-area {
  background-color: #fff !important;
}

.selector {
  .modal-body {
    padding: 0 !important;
  }

  &.sidebar-left {
    height: 100%;
  }

  .sidebar {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .sidebar-content {
    border-right: 1px solid #ebe9f1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
  }

  .media-library-fixed-search {
    border-bottom: 1px solid #ebe9f1;
    padding: 0 1.286rem;
    width: 360px;
    height: 65px;
    display: flex;
  }

  .media-library-list-title {
    margin: 2rem 1.286rem 0.5rem;
  }

  .media-library-user-list-wrapper {
    position: relative;
    height: calc(100% - 65px);
    width: 360px;
  }

  .sidebar-close-icon {
    display: none;
  }
}
</style>
