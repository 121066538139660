import http from '@/http/index'

const contentTypes = {
  async getModels(query) {
    return await http.get('/content-types/models', { params: query })
  },
  async getModel(id) {
    return await http.get(`/content-types/models/${id}`)
  },
  async createModel(data) {
    return await http.post('/content-types/models', data)
  },
  async updateModel(id, data) {
    return await http.put(`/content-types/models/${id}`, data)
  },
  async deleteModel(id) {
    return await http.delete(`/content-types/models/${id}`)
  },
  async getComponents() {
    return await http.get('/content-types/components')
  },
  async getComponent(id) {
    return await http.get(`/content-types/components/${id}`)
  },
  async createComponent(data) {
    return await http.post('/content-types/components', data)
  },
  async updateComponent(id, data) {
    return await http.put(`/content-types/components/${id}`, data)
  },
  async deleteComponent(id) {
    return await http.delete(`/content-types/components/${id}`)
  },
}

export default contentTypes
