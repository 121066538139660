<template>
  <div>
    <div class="mb-1">
      <b-row v-for="(item, index) in internal" :key="`kv-${index}`">
        <b-col cols="6" class="pr-0 mr-0">
          <text-input :value="item" @input="onInput($event, index)" placeholder="Value" />
        </b-col>
        <b-col cols="1" class="pl-0">
          <b-button variant="link" @click="onRemoveItem(index)" class="pl-25 text-danger">remove</b-button>
        </b-col>
      </b-row>
    </div>
    <b-button variant="primary" @click="onAddItem"><feather-icon icon="PlusIcon" class="mr-25" /> Add</b-button>
  </div>
</template>
<script>
export default {
  name: 'ValueArrayField',
  props: ['field', 'component', 'value', 'parentKey', 'locale', 'errors', 'index'],
  components: {},
  data() {
    return {
      internal: [],
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.internal = [...val]
        } else {
          this.internal = []
        }
      },
    },
  },
  computed: {},
  mounted() {},
  methods: {
    onInput(event, index) {
      this.internal[index] = event
      this.$emit('input', [...this.internal])
    },
    onAddItem() {
      this.internal.push('')
      this.$emit('input', [...this.internal])
    },
    onRemoveItem(index) {
      this.internal.splice(index, 1)
      this.$emit('input', [...this.internal])
    },
  },
}
</script>
