export const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    meta: { layout: '', requiresAuth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { layout: '', requiresAuth: true },
    component: () => import(/* webpackChunkName: "main" */ '@/views/Dashboard/Dashboard.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    redirect: '/settings/general',
    meta: {
      layout: '',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Index.vue'),
    children: [
      {
        path: 'general',
        name: 'SettingsGeneral',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/General/Index.vue'),
      },
      {
        path: 'accounts',
        name: 'SettingsAccounts',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Accounts/Index.vue'),
      },
      {
        path: 'roles',
        name: 'SettingsRoles',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Roles/Index.vue'),
      },
      {
        path: 'tokens',
        name: 'SettingsApiKeys',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/ApiKeys/Index.vue'),
      },
      {
        path: 'webhooks',
        name: 'SettingsWebhooks',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Webhooks/Index.vue'),
      },
    ],
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      layout: '',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users/Index.vue'),
  },
  {
    path: '/users/new',
    name: 'NewUser',
    meta: {
      layout: '',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users/New.vue'),
  },
  {
    path: '/users/:id',
    name: 'EditUser',
    meta: {
      layout: '',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users/Edit.vue'),
  },
  {
    path: '/roles',
    name: 'Roles',
    meta: {
      layout: '',
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/Roles/Index.vue'),
  },
  {
    path: '/model-builder',
    name: 'ModelBuilder',
    meta: { layout: '', requiresAuth: true, contentRenderer: 'sidebar-left', contentClass: 'model-builder-application' },
    component: () => import(/* webpackChunkName: "main" */ '@/views/ModelBuilder/Index.vue'),
  },
  {
    path: '/model-builder/system',
    name: 'SystemModelBuilder',
    meta: { layout: '', requiresAuth: true, contentRenderer: 'sidebar-left', contentClass: 'model-builder-application' },
    component: () => import(/* webpackChunkName: "main" */ '@/views/ModelBuilder/System.vue'),
  },
  {
    path: '/media-library',
    name: 'MediaLibrary',
    meta: { layout: '', requiresAuth: true, contentRenderer: 'sidebar-left', contentClass: 'media-library-application' },
    component: () => import(/* webpackChunkName: "media" */ '@/views/MediaLibrary/Index.vue'),
  },
  {
    path: '/graphql/playground',
    name: 'GraphQlPlayground',
    meta: { layout: '', requiresAuth: true, contentClass: 'graphql-application' },
    component: () => import(/* webpackChunkName: "graphql" */ '@/views/GraphQL/index.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'full' },
    component: () => import(/* webpackChunkName: "public" */ '@/views/Login.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
