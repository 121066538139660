<template>
  <object :data="logoUrl" />
</template>
<script>
export default {
  props: ['type'],
  computed: {
    logoUrl() {
      if (this.type) {
        return require(`@/assets/images/logo/logo-${this.type}.svg`)
      }

      return require('@/assets/images/logo/logo.svg')
    },
  },
}
</script>
