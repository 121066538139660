import http from '@/http/index'

const apikeys = {
  async getApikeys(query) {
    return await http.get(`/admin/apikeys`, { params: query })
  },
  async getApikey(id) {
    return await http.get(`/admin/apikeys/${id}`)
  },
  async createApikey(data) {
    return await http.post(`/admin/apikeys`, data)
  },
  async updateApikey(id, data) {
    return await http.put(`/admin/apikeys/${id}`, data)
  },
  async deleteApikey(id) {
    return await http.delete(`/admin/apikeys/${id}`)
  },
  async getPermissionSet() {
    return await http.get(`/admin/apikeys/permissions`)
  },
}

export default apikeys
