<template>
  <div>
    <b-button @click="newAccountModalVisible = true" variant="primary" v-if="$acl.can('admin.accounts.create')"
      ><feather-icon icon="PlusIcon" class="mr-25" /> Create account</b-button
    >
    <b-modal
      v-model="newAccountModalVisible"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="lg"
      @hidden="reset"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
    >
      <div class="px-1 pt-1">
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <feather-icon class="text-dark" icon="UserIcon" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            New Account
          </h4>
          <b-card-text>
            Create a new account to access the admin interfaces
          </b-card-text>
        </b-media>
        <hr class="mt-0 mb-2" />
        <form-alert :errors="serverErrors" />
        <validation-observer ref="accountForm">
          <b-form v-on:keyup.enter="onSave" @submit.prevent>
            <b-row>
              <!-- names -->
              <b-col cols="6">
                <text-input v-model="account.firstName" placeholder="First Name" label="First Name" validation-rules="required" />
              </b-col>

              <b-col cols="6">
                <text-input v-model="account.lastName" placeholder="Last Name" label="Last Name" validation-rules="required" />
              </b-col>

              <!-- username -->
              <b-col cols="12">
                <text-input v-model="account.username" placeholder="Username" label="Username (email)" validation-rules="required|email" type="email" />
              </b-col>

              <!-- password -->
              <b-col cols="6">
                <text-input v-model="account.password" placeholder="Password" label="Password" validation-rules="required" type="password" vid="password" />
              </b-col>

              <b-col cols="6">
                <text-input
                  v-model="confirmPassword"
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  validation-rules="required|confirmpassword:password"
                  type="password"
                />
              </b-col>

              <b-col cols="6">
                <validation-provider rules="required" name="roles" v-slot="{ errors }" tag="div">
                  <b-form-group label="Roles" label-for="roles" :class="errors.length > 0 ? 'is-invalid' : null">
                    <v-select id="roles" multiple v-model="account.roles" :options="roles" :reduce="role => role.id" label="name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- status -->
              <b-col cols="12">
                <b-form-group>
                  <div class="inline-spacing">
                    <b-form-checkbox id="isActive" name="isActive" v-model="account.isActive">
                      Is Active
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { accounts } from '@/api/index'
import vSelect from 'vue-select'
import DefaultNotifications from '@/components/Notification/default'
import { extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'

extend('confirmpassword', {
  ...confirmed,
  message: 'The passwords do not match',
})

export default {
  name: 'NewAccount',
  props: ['roles'],
  components: {
    vSelect,
  },
  data() {
    return {
      newAccountModalVisible: false,
      account: { roles: [], isActive: true },
      formErrors: false,
      serverErrors: [],
      confirmPassword: '',
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {
    async onSave(e) {
      e.preventDefault()

      var self = this

      var validation = await self.$refs.accountForm.validateWithInfo()

      if (validation.isValid) {
        accounts
          .createAccount(self.account)
          .then(res => {
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.newAccountModalVisible = false
            self.$emit('created')
          })
          .catch(err => {
            self.formErrors = true
            if (err.response.status == 422) {
              self.serverErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      }
    },
    reset() {
      this.newAccountModalVisible = false
      this.account = { roles: [], isActive: true }
    },
  },
}
</script>
