<template>
  <div>
    <b-form-group class="w-25">
      <validation-provider #default="{ errors }" :name="field.label" :rules="rules">
        <b-input-group>
          <b-form-input :value="value" @input="onTimeInput" type="text" placeholder="HH:mm:ss" size="lg"></b-form-input>
          <b-input-group-append>
            <b-form-timepicker
              button-only
              show-seconds
              now-button
              reset-button
              button-variant="primary"
              :hour12="false"
              no-close-button
              :value="value"
              @input="onTimeInput"
            ></b-form-timepicker>
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: 'TimeField',
  props: ['field', 'value', 'locale', 'errors', 'index'],
  data() {
    return {
      internal: false,
    }
  },
  watch: {},
  computed: {
    rules() {
      const ruleSet = []

      if (this.field.required) {
        ruleSet.push('required')
      }
      return ruleSet.join('|')
    },
  },
  methods: {
    onTimeInput(event) {
      console.log(event)
      if (event) {
        this.$emit('input', event)
      } else {
        this.$emit('input', '')
      }
    },
  },
}
</script>
