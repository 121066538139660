<template>
  <b-row class="justify-content-md-center" :class="{ hidden: field.hidden }">
    <b-col cols="12" lg="12">
      <div class="bg-white editor-field" :class="{ 'rounded shadow mb-1 px-2 py-1': !nested }" v-b-hover="displayApiKey">
        <div class="mb-1">
          <div class="d-flex justify-content-between align-item-center">
            <div class="d-flex">
              <feather-icon icon="AlertTriangleIcon" class="text-danger align-self-center p-0 mr-25" v-if="hasErrors" />
              <div class="h5 mb-0 align-self-end" :class="{ 'field-error': hasErrors }">
                {{ label }}
              </div>
              <feather-icon
                icon="GlobeIcon"
                v-if="!isCrossLanguageField"
                class="text-secondary align-self-center p-0 ml-1"
                v-b-tooltip.hover.v-secondary.bottom
                title="Localizable"
              />
              <div class="ml-1 mb-0 align-items-center"></div>
            </div>
            <div class="align-self-end">
              <div class="font-small-2 text-muted p-0 m-0" v-if="showApiKey">
                <em>{{ field.key }}</em>
              </div>
            </div>
          </div>
          <b-form-text v-if="field.help" class="mt-0">{{ field.help }}</b-form-text>
        </div>
        <component
          :is="fieldType"
          :field="field"
          :value="internal"
          :parent-key="absoluteKeyPath"
          :locale="locale"
          @input="onInput"
          :read-only="readOnly"
          :errors="errors"
          :index="index"
        />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { required } from '@validations'
import Accounts from '@/views/Settings/Accounts/Index.vue'

export default {
  name: 'FieldEditor',
  components: { Accounts },
  // props: ['field', 'value', 'nested', 'parent-key', 'locale', 'model', 'readOnly', 'errors', 'index'],
  props: {
    field: {
      type: Object,
      default: null,
    },
    value: {},
    nested: {
      type: Boolean,
      default: false,
    },
    parentKey: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    model: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: [],
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      internal: '',
      showApiKey: false,
      hasErrors: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.internal = val
      },
    },
    errors: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (!newValue || newValue.length == 0) {
          this.hasErrors = false
          this.$emit('on-error-status-change', false)
        } else {
          for (let i = 0; i < newValue.length; i++) {
            let error = newValue[i].paths.find(e => e.path == this.absoluteKeyPath)
            if (error && this.index == error.index) {
              this.hasErrors = true
              this.$emit('on-error-status-change', true)
            }
          }
        }
      },
    },
  },
  computed: {
    fieldType() {
      return this.field.type + '-field'
    },
    label() {
      if (this.field.required) {
        return this.field.label + '*'
      } else {
        return this.field.label
      }
    },
    isComponent() {
      return this.field.type == 'singlecomponent' || this.field.type == 'multiplecomponent'
    },
    absoluteKeyPath() {
      if (this.parentKey) return `${this.parentKey}.${this.field.key}`

      return this.field.key
    },
    isCrossLanguageField() {
      return !this.field.localizable && !this.nested && (this.model ? !this.model.system : true)
    },
  },

  created() {},
  methods: {
    onInput(evt) {
      this.$emit('input', evt)
      if (this.field.localizable === false) {
        this.$emit('cross-locale-value-change', { event: evt, field: this.field, locale: this.locale, path: this.absoluteKeyPath })
        //this.$bus.$emit('cross-locale-value-change', { event: evt, field: this.field, locale: this.locale, path: this.absoluteKeyPath })
      }
    },
    onError() {
      this.hasErrors = true
    },
    displayApiKey(hovered) {
      this.showApiKey = hovered
    },
  },
}
</script>
