<template>
  <b-alert v-height-fade.appear :variant="variant" class="mb-1" :show="errors && errors.length > 0">
    <div class="alert-body">
      <div><feather-icon icon="InfoIcon" class="mr-50" /> {{ title }}</div>
      <div>
        <ul class="mt-1 ml-0">
          <li v-for="(error, index) in errors" :key="index" class="font-weight-normal">{{ error.message }}</li>
        </ul>
      </div>
    </div>
  </b-alert>
</template>
<script>
import { heightFade } from '@core/directives/animations'
export default {
  name: 'FormAlert',
  props: {
    variant: {
      type: String,
      default: 'danger',
    },
    title: {
      type: String,
      default: 'Error!',
    },
    errors: {
      type: Array,
      default: null,
    },
  },
  directives: {
    'height-fade': heightFade,
  },
}
</script>
