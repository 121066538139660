import { admin } from '@/api/index'
import _ from 'lodash'

const state = {
  settings: null,
  contentColumns: null,
  isVerticalMenuCollapsed: true,
}
const getters = {
  Settings: state => state.settings,
  ContentColumns: state => (state.contentColumns ? state.contentColumns : []),
}
const actions = {
  async LoadSettings({ commit }) {
    let settings = await admin.getSettings()
    commit('SetSettings', settings.data)
  },
  SetContentColumns({ commit, state }, data) {
    let cols = getters.ContentColumns(state)

    var modelIndex = _.findIndex(cols, c => {
      return c.model == data.model
    })

    if (modelIndex < 0) {
      cols.push(data)
    } else {
      cols[modelIndex] = data
    }

    commit('SetContentColumns', cols)
  },
}
const mutations = {
  SetSettings(state, settings) {
    state.settings = settings
  },
  SetContentColumns(state, data) {
    state.contentColumns = data
  },
  UpdateVerticalMenuCollapsed(state, val) {
    state.isVerticalMenuCollapsed = val
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
