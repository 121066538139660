import axios from 'axios'
import router from '../router'
import store from '../store'

axios.defaults.baseURL = '/api'

axios.interceptors.response.use(
  res => {
    return res
  },
  error => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      store.dispatch('LogOut')
      return router.push('/login')
    } else {
      return Promise.reject(error)
    }
  }
)

axios.interceptors.request.use(config => {
  if (store.getters.isAuthenticated) {
    config.headers.Authorization = 'Bearer ' + store.getters.User.access_token
  }

  return config
})

const http = axios

export default http
