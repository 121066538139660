<template>
  <b-card class="d-sm-none d-lg-block">
    <div class="m-0 h5 d-flex align-items-center text-secondary"><feather-icon icon="ZapIcon" class="mr-25" /> Actions</div>
    <hr />
    <b-row v-if="!model.isSingle" class="pl-1">
      <b-button variant="link" class="font-weight-normal text-primary pl-0" @click="$emit('duplicate-content')" v-if="$acl.can(duplicateContentsPermission)"
        ><feather-icon icon="CopyIcon" /> Duplicate</b-button
      >
    </b-row>
    <b-row class="pl-1">
      <b-button variant="link" class="font-weight-normal text-danger pl-0" @click="$emit('delete-content')" v-if="$acl.can(deleteContentsPermission)"
        ><feather-icon icon="TrashIcon" /> Delete</b-button
      >
    </b-row>
    <b-row v-if="model.localizable" class="pl-1">
      <b-button variant="link" class="font-weight-normal text-danger pl-0" @click="$emit('delete-locale-content')" v-if="$acl.can(deleteContentsPermission)"
        ><feather-icon icon="TrashIcon" /> Delete this language</b-button
      >
    </b-row>
  </b-card>
</template>
<script>
export default {
  name: 'ActionsBlock',
  props: ['model'],
  components: {},
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  computed: {
    updateContentsPermission() {
      return `contents.${this.model.id}.update`
    },
    duplicateContentsPermission() {
      return `contents.${this.model.id}.duplicate`
    },
    deleteContentsPermission() {
      return `contents.${this.model.id}.delete`
    },
  },
  created() {},
  methods: {},
}
</script>
<style scoped>
ul,
li {
  list-style: none;
  list-style-type: none;
}
</style>
