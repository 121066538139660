export default [
  {
    header: 'Media',
    permissions: ['medialibrary.*'],
  },
  {
    title: 'Media Library',
    route: 'MediaLibrary',
    icon: 'ImageIcon',
    permissions: ['medialibrary.*'],
  },
  {
    header: 'Users & Roles',
    permissions: ['usersroles.users.read', 'usersroles.roles.read'],
  },
  {
    title: 'Users',
    route: 'Users',
    icon: 'UsersIcon',
    permissions: ['usersroles.users.read'],
  },
  {
    title: 'Roles',
    route: 'Roles',
    icon: 'KeyIcon',
    permissions: ['usersroles.roles.read'],
  },
  {
    header: 'Administration',
    permissions: ['modelbuilder.*', 'admin.*'],
  },
  {
    title: 'Model Builder',
    route: 'ModelBuilder',
    icon: 'LayersIcon',
    permissions: ['modelbuilder.models.manage', 'modelbuilder.components.manage'],
  },
  {
    title: 'Settings',
    route: 'Settings',
    icon: 'SettingsIcon',
    permissions: ['admin.*'],
  },
  {
    title: 'GraphQL',
    route: 'GraphQlPlayground',
    iconimage: require('../../assets/images/icons/graphql.png'),
    permissions: ['admin.*', 'modelbuilder.*'],
  },
]
