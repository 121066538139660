import http from '@/http/index'

const contents = {
  async getContents(type, query) {
    return await http.get(`/contents/${type}`, { params: query })
  },
  async getContent(type, id, query) {
    return await http.get(`/contents/${type}/${id}`, { params: query })
  },
  async createContent(type, data) {
    return await http.post(`/contents/${type}`, data)
  },
  async publishContent(type, id) {
    return await http.post(`/contents/${type}/${id}/publish`)
  },
  async unpublishContent(type, id) {
    return await http.post(`/contents/${type}/${id}/unpublish`)
  },
  async createContentTranslation(type, id, locale) {
    return await http.post(`/contents/${type}/${id}/create-translation`, { id: id, locale: locale })
  },
  async updateContent(type, id, data) {
    return await http.put(`/contents/${type}/${id}`, data)
  },
  async duplicateContentAll(type, id) {
    return await http.post(`/contents/${type}/${id}/duplicate`, { locales: true })
  },
  async updateLocaleSet(type, data) {
    return await http.put(`/contents/${type}/locale-set`, data)
  },
  async deleteContent(type, id, allLocales) {
    if (allLocales) {
      return await http.delete(`/contents/${type}/${id}`, { params: { locales: allLocales } })
    } else {
      return await http.delete(`/contents/${type}/${id}`)
    }
  },
  async deleteAllContents(type) {
    return await http.delete(`/contents/${type}/clear`)
  },
  createFilter(name, operator) {
    return `filter[${name}][${operator}]`
  },
}

export default contents
