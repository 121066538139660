<template>
  <b-card class="d-sm-none d-lg-block">
    <div class="m-0 h5 d-flex align-items-center text-secondary"><feather-icon icon="LinkIcon" class="mr-25" /> Links</div>
    <hr />
    <ul class="pl-0 pt-1 mt-0">
      <li v-for="(link, index) in model.properties.links" :key="`content_link_${index}`" class="mb-1">
        <a :href="composeLink(link)" target="_blank">{{ link.key }}</a>
      </li>
    </ul>
  </b-card>
</template>
<script>
export default {
  name: 'LinksBlock',
  props: ['model', 'content'],
  components: {},
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  computed: {},
  created() {},
  methods: {
    composeLink(link) {
      let result = '#'

      if (link.value) {
        result = link.value.replace('{_id}', this.content.id).replace('{_hash}', this.content.hash)

        this.model.attributes.forEach(a => {
          result = result.replace(`{${a.key}}`, this.content.attributes[a.key])
        })
      }

      return result
    },
  },
}
</script>
<style scoped>
ul,
li {
  list-style: none;
  list-style-type: none;
}
</style>
