import http from '@/http/index'

const webhooks = {
  async createWebhook(data) {
    return await http.post(`/admin/webhooks`, data)
  },
  async getWebhooks() {
    return await http.get(`/admin/webhooks`)
  },

  async updateWebhook(id, data) {
    return await http.put(`/admin/webhooks/${id}`, data)
  },

  async deleteWebhook(id) {
    return await http.delete(`/admin/webhooks/${id}`)
  },
}

export default webhooks
