import http from '@/http/index'

const mediaLibrary = {
  async getFolders() {
    return await http.get('/media-library/folders')
  },

  async moveFolder(data) {
    return await http.post('/media-library/folders/move', data)
  },

  async createFolder(data) {
    return await http.post('/media-library/folders', data)
  },

  async deleteFolder(id) {
    return await http.delete('/media-library/folders', { params: { folder: id } })
  },

  async renameFolder(data) {
    return await http.post('/media-library/folders/rename', data)
  },

  async getMedia(id) {
    return await http.get(`/media-library/media/${id}`)
  },

  async getMedias(query) {
    return await http.get('/media-library/media', { params: { ...query } })
  },

  async deleteMedia(id) {
    return await http.delete(`/media-library/media/${id}`)
  },

  async uploadImage(data) {
    return await http.post(`/media-library/media/upload/image`, data)
  },
}

export default mediaLibrary
