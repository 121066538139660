import { accounts } from '@/api/index'
import moment from 'moment'

const state = {
  user: null,
  me: null,
}
const getters = {
  isAuthenticated: state => {
    if (state.user && state.user.expires_at) {
      let exp = moment.utc(state.user.expires_at)

      if (exp.diff(moment.utc()) < 0) {
        return false
      }

      return true
    }

    return false
  },
  User: state => state.user,
  Me: state => state.me,
}

const actions = {
  async LogIn({ commit }, credentials) {
    await accounts.login(credentials).then(res => {
      commit('setUser', res.data)
    })
  },
  async LoadMe({ commit }, credentials) {
    await accounts.getMe().then(res => {
      commit('setMe', res.data)
    })
  },
  async LogOut({ commit }) {
    let user = null
    commit('logout', user)
  },
}

const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setMe(state, me) {
    state.me = me
  },
  logout(state, user) {
    state.user = user
  },
}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
