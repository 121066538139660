<template>
  <div style="margin:0rem 1.286rem 0.5rem">
    <liquor-tree
      ref="folderTree"
      class="tree--small"
      :data="folders"
      :options="options"
      v-if="folders != null && folders.length > 0"
      @node:selected="onNodeSelected"
      @node:dragging:finish="onDragFinish"
      @tree:mounted="onDataReceived"
      @node:editing:stop="onRenameNode"
    >
      <div class="tree-scope w-100" slot-scope="{ node }">
        <template v-if="node.data.isRoot">
          <div class="d-flex align-content-center">
            <span class="node-text text align-baseline">
              {{ node.text }}
            </span>
          </div>
        </template>
        <template v-else>
          <div class="d-flex align-content-center justify-content-between">
            <div class="node-text text align-baseline">
              {{ node.text }}
            </div>
            <div class="tree-node-actions align-right">
              <b-button variant="link" size="sm" class="ml-1 p-0" @click.stop="renameNode(node)" v-if="$acl.can('medialibrary.folders.rename')"
                ><feather-icon class="text-secondary p-0" icon="EditIcon"
              /></b-button>
              <b-button variant="link" size="sm" class="ml-1 p-0" @click.stop="confirmDelete(node)" v-if="$acl.can('medialibrary.folders.delete')"
                ><feather-icon class="text-danger p-0" icon="TrashIcon"
              /></b-button>
            </div>
          </div>
        </template>
      </div>
    </liquor-tree>
    <portal to="media-sidebar-header">
      <div>
        <b-button variant="primary" size="sm" @click="newFolderModal = true" v-if="$acl.can('medialibrary.folders.create')">Add Folder</b-button>
        <b-modal
          :no-close-on-backdrop="true"
          :no-close-on-esc="true"
          v-model="newFolderModal"
          hide-header
          centered
          cancel-variant="outline-secondary"
          ok-title="Ok"
          cancel-title="Close"
          @ok="createFolder"
          @hide="$emit('close')"
          @hidden="onHidden"
        >
          <b-card-body>
            <b-row>
              <b-col class="cursor-pointer">
                <validation-observer ref="folderForm">
                  <b-form v-on:keyup.enter="createFolder" @submit.prevent>
                    <div class="mb-1">
                      <div class=" d-flex">
                        <div class="h5 mb-0 align-self-end">Folder name*</div>
                      </div>
                    </div>
                    <b-form-group>
                      <validation-provider #default="{ errors }" name="Name" rules="required">
                        <b-form-input id="name" placeholder="Name of the folder..." size="md" v-model="folderName" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-form>
                </validation-observer>
              </b-col>
            </b-row>
          </b-card-body>
        </b-modal>
      </div>
    </portal>
    <b-modal id="deleteFolderConfirmModal" hide-footer title="Image Editor" size="md" centered hide-header>
      <div class="d-block text-left mt-2">
        <h4>Delete Folder</h4>
        <p class="h5 font-weight-normal">{{ selectedForDelete.name }}</p>
        <p class="h6 font-weight-normal mt-2">Deleting the folder will delete all subfolders and files.</p>
        <p>Are you sure you want to delete it?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="$bvModal.hide('deleteFolderConfirmModal')">Cancel</b-button>
        <b-button class="" variant="danger" @click="deleteFolder(selectedForDelete.id)">Yes, delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mediaLibrary } from '@/api/index'
import LiquorTree from 'liquor-tree'
import DefaultNotifications from '@/components/Notification/default'
import { required } from '@validations'

export default {
  props: [],
  components: {
    LiquorTree,
  },
  watch: {},
  data() {
    return {
      selected: null,
      selectedForDelete: {
        id: '',
        name: '',
      },
      items: null,
      newFolderModal: false,
      folderPath: '/',
      folderName: '',
      required,
      options: {
        checkbox: false,
        parentSelect: true,
        propertyNames: {
          text: 'text',
          children: 'children',
        },
        dnd: {
          onDragStart(node) {
            return node.data.isNotDraggable !== true
          },
          onDragOn(targetNode, destinationNode, dropPosition) {
            // console.log('onDragOn', targetNode.text, destinationNode.text, dropPosition)
            return destinationNode.data.isNotDroppable !== true
          },
        },
      },
    }
  },
  created() {
    const self = this
    self.fetchFolders()
  },
  computed: {
    folders() {
      if (this.items) {
        const prepareNode = f => ({
          text: f.text,
          data: {
            isRoot: f.path === '/',
            path: f.path,
            isNotDraggable: !this.$acl.can('medialibrary.folders.move'),
          },
          state: {
            expanded: (!this.selected && f.path == '/') || this.folderPath.startsWith(f.path),
          },
          children: f.children ? f.children.map(prepareNode) : [],
        })

        let tree = this.items.map(prepareNode)
        return tree
      }
      return null
    },
  },
  mounted() {},
  methods: {
    fetchFolders() {
      const self = this
      self.items = null

      mediaLibrary.getFolders().then(res => {
        self.items = res.data
      })
    },
    renameNode(node) {
      node.startEditing()
    },
    onRenameNode(node, isTextChanged) {
      const pattern = new RegExp('\/')
      if (pattern.test(node.data.text)) {
        node.data.text = isTextChanged
        return
      }

      if (node.data.text != isTextChanged) {
        const self = this

        var payload = {
          folder: node.data.path,
          newName: node.data.text,
        }

        mediaLibrary
          .renameFolder(payload)
          .then(res => {
            self.fetchFolders()
            self.$emit('folder-renamed')
          })
          .catch(err => {
            self.$bus.$emit('notification', { ...DefaultNotifications.saveError, text: '', details: err.response.data.errors[0].message })
          })
      }
    },
    confirmDelete(node) {
      this.selectedForDelete.id = node.data.path
      this.selectedForDelete.name = node.data.text
      this.$bvModal.show('deleteFolderConfirmModal')
    },
    deleteFolder() {
      const self = this
      if (this.selectedForDelete.id != '') {
        mediaLibrary
          .deleteFolder(this.selectedForDelete.id)
          .then(res => {
            self.folderPath = '/'
            self.items = null
            self.fetchFolders()
            self.$emit('folder-deleted')
            this.$bvModal.hide('deleteFolderConfirmModal')
          })
          .catch(err => {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, text: '', details: err.response.data.errors[0].message })
          })
      }
    },
    onHidden() {
      this.newFolderModal = false
      this.folderName = ''
    },
    async createFolder(e) {
      e.preventDefault()
      let self = this
      return await self.$refs.folderForm.validate().then(success => {
        if (success) {
          let payload = {
            folder: `${self.selected ? self.selected.data.path : '/'}${self.folderName}/`,
          }

          mediaLibrary
            .createFolder(payload)
            .then(res => {
              self.items = null
              self.folderPath = res.data.result
              self.fetchFolders()
              self.$emit('folder-created', { path: res.data.result })
              self.newFolderModal = false
            })
            .catch(err => {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, text: '', details: err.response.data.errors[0].message })
            })
          return true
        } else {
          return false
        }
      })
    },
    onDataReceived(e) {
      let selection = this.$refs.folderTree.findAll({ data: { path: this.folderPath } })
      selection.select()
      // this.onNodeSelected(selection[0])
    },
    onNodeSelected(node) {
      this.selected = node
      node.expand()
      var path = node.data.path

      // if (this.$route.query.folder != path) {
      //   if (path == '/' && this.$route.query.folder == undefined) {
      //   } else if (path == '/' && this.$route.query.folder != undefined) {
      //     this.$router.push({ query: {} })
      //   } else {
      //     this.$router.push({ query: { folder: node.data.path } })
      //   }
      // }

      this.$emit('folder-selected', { text: node.data.text, path: node.data.path })
    },
    async onDragFinish(targetNode, destinationNode, dropPosition) {
      let destination = destinationNode.data.path

      if (dropPosition == 'drag-above' || dropPosition == 'drag-below') {
        destination = destinationNode.data.path.substring(1, destinationNode.data.path.length - 1)
        const destinationSegments = destination.split('/')
        destinationSegments.pop()

        destination = `/${destinationSegments.join('/')}/`

        if (destination === '//') {
          destination = '/'
        }
      }

      await mediaLibrary.moveFolder({
        folder: targetNode.data.path,
        moveTo: destination,
      })

      targetNode.data.path = `${destination}${targetNode.text}/`

      this.$bus.$emit('notification', { ...DefaultNotifications.saveSuccessful, text: 'Folder moved' })
      this.$emit('folder-moved', { node: targetNode, destination: destinationNode })
    },
  },
}
</script>
<style lang="scss">
.tree-node {
  padding: 0px !important;
}
.node-text {
  line-height: 24px;
  padding-left: 5px;
}
.tree {
  padding: 0px;
}

.tree-arrow {
  margin-left: -5px !important;
  width: 15px !important;
}

.tree-content {
  .tree-node-actions {
    display: none;
  }
  &:hover {
    .tree-node-actions {
      display: block;
    }
  }
}
</style>
