<template>
  <div>
    <div class="p-1 border mb-1" style="min-height:250px">
      <b-row class="d-flex justify-content-center">
        <b-img :src="noSelectionImage" v-if="!internal || internal.length == 0" width="250" />
        <div v-else class="d-flex px-1">
          <draggable
            v-bind="dragOptions"
            v-model="internal"
            tag="div"
            class="row d-flex justify-content-center"
            handle=".drag-handle"
            @start="onDragFieldStart"
            @end="onDragFieldEnd"
            @change="onDragChanged"
            :group="{ name: 'fields' }"
          >
            <transition-group type="transition" name="flip-list" class="row d-flex justify-content-center">
              <div v-for="(item, index) in internal" :key="'mg_' + index" class="my-1" cols="2">
                <media-preview :media="item" :box-size="160" :gallery="true" @delete="onRemove(index)" @open="onOpen(index)" />
              </div>
            </transition-group>
          </draggable>
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center mt-1">
        <media-selector :max-selection="1000" @selection-done="onSelectionDone" class="mt-2" />
      </b-row>
    </div>
    <media-info ref="mediaInfo" @image-edit="openEdit" />
    <image-editor ref="imageEditor" @upload-completed="onUploadCompleted" />
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import MediaSelector from './MediaSelector.vue'
import MediaInfo from '@/views/MediaLibrary/components/MediaInfo.vue'
import MediaPreview from './MediaPreview.vue'
import ImageEditor from '@/components/ImageEditor/ImageEditor.vue'

export default {
  name: 'MediaGalleryField',
  props: ['field', 'value', 'errors', 'index'],
  components: { draggable, MediaSelector, MediaInfo, MediaPreview, ImageEditor },
  data() {
    return {
      internal: [],
      drag: false,
      noSelectionImage: require('@/assets/images/pages/under-maintenance.svg'),
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internal = val
      },
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'sortable-ghost',
      }
    },
    selected() {
      return this.internal != null && this.internal.id
    },
  },
  methods: {
    onDragFieldEnd(e) {
      this.drag = false
    },
    onDragFieldStart(e) {
      this.drag = true
    },
    onDragChanged(e) {
      this.$emit('input', [...this.internal])
    },
    onSelectionDone(items) {
      if (this.internal) {
        this.internal.push(...items)
      } else {
        this.internal = [...items]
      }

      this.$emit('input', [...this.internal])
    },
    onOpen(index) {
      this.$refs.mediaInfo.open(this.internal[index].id)
    },
    onRemove(index) {
      this.internal.splice(index, 1)
    },
    openEdit(item) {
      this.$refs.imageEditor.open(item)
    },
    onUploadCompleted() {},
  },
}
</script>
