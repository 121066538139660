import _ from 'lodash'
import moment from 'moment'

const fields = [
  {
    name: 'Text',
    hint: 'Titles, names, paragraph',
    image: 'text.svg',
    settings: {
      editor: 'single_line',
      min: 0,
      max: 0,
      unique: false,
      isTitle: false,
      defaultValue: null,
      type: 'text',
    },
    ui: {
      tables: {
        showable: true,
        sortable: true,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Rich Text',
    hint: 'A rich text editor with formatting options',
    image: 'rich-text.svg',
    settings: {
      editor: 'markdown',
      min: 0,
      max: 0,
      type: 'richtext',
      defaultValue: null,
    },
    ui: {
      tables: {
        showable: false,
        sortable: true,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Number',
    hint: 'Numbers (integer or decimal)',
    image: 'number.svg',
    settings: {
      editor: null,
      min: 0,
      max: 0,
      type: 'number',
      defaultValue: null,
    },
    ui: {
      tables: {
        showable: true,
        sortable: true,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Yes/No',
    hint: 'A simple yes/no field',
    image: 'boolean.svg',
    settings: {
      type: 'boolean',
    },
    ui: {
      tables: {
        showable: true,
        sortable: true,
        defaultValue: null,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Date',
    hint: 'Date or Date & Time',
    image: 'datetime.svg',
    settings: {
      editor: null,
      min: null,
      max: null,
      type: 'datetime',
    },
    ui: {
      tables: {
        showable: true,
        sortable: true,
        cellFormatter: (value, key, item) => {
          if (value) {
            return moment(value).format('YYYY-MM-DD')
          }
          return value
        },
      },
    },
  },
  {
    name: 'Time',
    hint: 'Time only',
    image: 'time.svg',
    settings: {
      min: null,
      max: null,
      type: 'time',
    },
    ui: {
      tables: {
        showable: true,
        sortable: true,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Single Choise',
    hint: 'Single choise enumeration',
    image: 'single-enumeration.svg',
    settings: {
      editor: null,
      enum: [],
      min: 0,
      max: 0,
      type: 'singlechoise',
    },
    ui: {
      tables: {
        showable: true,
        sortable: true,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Enumeration',
    hint: 'Multiple choise lists',
    image: 'enumeration.svg',
    settings: {
      editor: null,
      enum: [],
      min: 0,
      max: 0,
      type: 'enumeration',
    },
    ui: {
      tables: {
        showable: true,
        sortable: true,
        cellFormatter: (value, key, item) => {
          if (value) {
            if (Array.isArray(value)) {
              return value.join(',')
            }
          }
          return value
        },
      },
    },
  },
  {
    name: 'Tags',
    hint: 'Multiple tagging system',
    image: 'tag.svg',
    settings: {
      enum: [],
      min: 0,
      max: 0,
      type: 'tag',
    },
    ui: {
      tables: {
        showable: true,
        sortable: true,
        cellFormatter: (value, key, item) => {
          if (value) {
            if (Array.isArray(value)) {
              return value.join(',')
            }
          }
          return value
        },
      },
    },
  },
  {
    name: 'Media',
    hint: 'Single image, document or video',
    image: 'media.svg',
    settings: {
      size: {
        type: 'kbyte',
        min: 0,
        max: 0,
      },
      dimensions: {
        minWidth: 0,
        maxWidth: 0,
        minHeight: 0,
        maxHeight: 0,
      },
      allowedTypes: [],
      allowedExtensions: [],
      type: 'media',
    },
    ui: {
      tables: {
        showable: true,
        sortable: false,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Media Gallery',
    hint: 'Multiple images, documents or videos',
    image: 'media-gallery.svg',
    settings: {
      enum: [],
      size: {
        type: 'kbyte',
        min: 0,
        max: 0,
      },
      dimensions: {
        minWidth: 0,
        maxWidth: 0,
        minHeight: 0,
        maxHeight: 0,
      },
      allowedTypes: [],
      allowedExtensions: [],
      min: 0,
      max: 0,
      type: 'mediagallery',
    },
    ui: {
      tables: {
        showable: true,
        sortable: false,
        cellFormatter: (value, key, item) => {
          if (value) {
            if (Array.isArray(value)) {
              return value.length + ' Media'
            }
          }
          return value
        },
      },
    },
  },
  {
    name: 'Color Picker',
    hint: 'RGB, Hex picker',
    image: 'color-picker.svg',
    settings: {
      type: 'colorpicker',
    },
    ui: {
      tables: {
        showable: true,
        sortable: false,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Map',
    hint: 'Coordinates, latitude & longitude on a map',
    image: 'map.svg',
    settings: {
      editor: null,
      type: 'map',
    },
    ui: {
      tables: {
        showable: false,
        sortable: false,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Key/Value',
    hint: 'Embed an array of repeatable key/value items',
    image: 'multi-components.svg',
    settings: {
      min: 0,
      max: 0,
      type: 'keyvalue',
    },
    ui: {
      tables: {
        showable: true,
        sortable: false,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Value Array',
    hint: 'Embed an array of repeatable values',
    image: 'multi-components.svg',
    settings: {
      min: 0,
      max: 0,
      type: 'valuearray',
    },
    ui: {
      tables: {
        showable: true,
        sortable: false,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Json',
    hint: 'JSON data',
    image: 'json.svg',
    settings: {
      type: 'json',
    },
    ui: {
      tables: {
        showable: false,
        sortable: false,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Relations',
    hint: 'One or more references to entries of other collections',
    image: 'relations.svg',
    settings: {
      type: 'relation',
      min: 0,
      max: 0,
    },
    ui: {
      tables: {
        showable: true,
        sortable: false,
        cellFormatter: (value, key, item) => {
          if (value) {
            if (Array.isArray(value)) {
              return value.length + '  Elements'
            }
          }
          return value
        },
      },
    },
  },
  {
    name: 'Component',
    hint: 'Embed a pre-defined component',
    image: 'component.svg',
    settings: {
      allowedType: {},
      type: 'singlecomponent',
    },
    ui: {
      tables: {
        showable: false,
        sortable: false,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
  {
    name: 'Multi Component',
    hint: 'Embed an array of repeatable pre-defined components',
    image: 'multi-components.svg',
    settings: {
      allowedTypes: [],
      min: 0,
      max: 0,
      type: 'multiplecomponent',
    },
    ui: {
      tables: {
        showable: true,
        sortable: false,
        cellFormatter: (value, key, item) => {
          return value
        },
      },
    },
  },
]

const helpers = {
  getManifestByType(type) {
    let index = _.findIndex(fields, function(o) {
      return o.settings.type == type
    })
    return fields[index]
  },
}

export { fields, helpers }
