<template>
  <div>
    <div class="p-1 border mb-1" style="min-height:150px">
      <b-row class="d-flex justify-content-center">
        <b-img :src="noSelectionImage" v-if="!value" width="150" />
        <div v-else class="d-flex px-1">
          <b-img :src="noSelectionImage" v-if="!value" width="150" />
          <media-preview :media="value" :box-size="160" @delete="onRemove" @open="onOpen" v-else />
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center mt-1">
        <media-selector :max-selection="1" @selection-done="onSelectionDone" class="mt-0" />
      </b-row>
    </div>
    <media-info ref="mediaInfo" @image-edit="openEdit" />
    <image-editor ref="imageEditor" @upload-completed="onUploadCompleted" />
  </div>
</template>
<script>
import MediaSelector from './MediaSelector.vue'
import MediaInfo from '@/views/MediaLibrary/components/MediaInfo.vue'
import MediaPreview from './MediaPreview.vue'
import ImageEditor from '@/components/ImageEditor/ImageEditor.vue'

export default {
  name: 'MediaField',
  props: ['field', 'value', 'errors', 'index'],
  components: { MediaSelector, MediaInfo, MediaPreview, ImageEditor },
  data() {
    return {
      noSelectionImage: require('@/assets/images/pages/under-maintenance.svg'),
    }
  },
  watch: {},
  computed: {
    selected() {
      return this.value != null && this.value.id
    },
  },
  methods: {
    onSelectionDone(items) {
      this.$emit('input', items[0])
    },
    onOpen(index) {
      this.$refs.mediaInfo.open(this.value.id)
    },
    onRemove(index) {
      this.$emit('input', null)
    },
    openEdit(item) {
      this.$refs.imageEditor.open(item)
    },
    onUploadCompleted() {},
  },
}
</script>
