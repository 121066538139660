<template>
  <div>
    <b-modal
      ref="imageEditorModal"
      dialog-class="media-library-media-info modal-full-screen"
      :no-close-on-backdrop="false"
      :no-close-on-esc="false"
      :hide-header="true"
      :hide-footer="true"
      v-model="visible"
      centered
      size="lg"
      ok-variant="outline-secondary"
      ok-only
      ok-title="Done"
      @hidden="reset"
    >
      <!-- <b-button variant="link" class="text-dark" @click="close"><feather-icon icon="XIcon" size="22"/></b-button> -->
      <div class="media-info-container d-flex h-100 flex-column">
        <div class="bg-black mt-auto d-flex align-items-center justify-content-center" style="min-height:65px"></div>
        <div class="bg-black position-relative flex-grow-1 overflow-hidden">
          <div class="d-flex align-items-center h-100 ml-auto justify-content-center py-2">
            <cropper
              class="cropper"
              ref="cropper"
              :src="imageUrl"
              :stencil-props="{}"
              :default-size="defaultAreaSize"
              @change="change"
              :debounce="true"
            ></cropper>
            <div class="size-info position-absolute mr-1 mb-1 text-white-75" v-if="size.width && size.height">
              <div>Width: {{ size.width }}px</div>
              <div>Height: {{ size.height }}px</div>
            </div>
          </div>
        </div>
        <div class="bg-black mt-auto d-inline-flex align-items-center justify-content-center" style="min-height:65px">
          <b-button size="sm" variant="link" title="Zoom In" @click="zoom(2)">
            <img :src="require('@/assets/images/icons/zoom-in.svg')" />
          </b-button>
          <b-button size="sm" variant="link" title="Zoom Out" @click="zoom(0.5)">
            <img :src="require('@/assets/images/icons/zoom-out.svg')" />
          </b-button>
          <b-button size="sm" variant="link" title="Flip Horizontal" @click="flip(true, false)">
            <img :src="require('@/assets/images/icons/flip-horizontal.svg')" />
          </b-button>
          <b-button size="sm" variant="link" title="Flip Vertical" @click="flip(false, true)">
            <img :src="require('@/assets/images/icons/flip-vertical.svg')" />
          </b-button>
          <b-button size="sm" variant="link" title="Flip Vertical" @click="rotate(-90)">
            <img :src="require('@/assets/images/icons/rotate-counter-clockwise.svg')" />
          </b-button>
          <b-button size="sm" variant="link" title="Flip Vertical" @click="rotate(90)">
            <img :src="require('@/assets/images/icons/rotate-clockwise.svg')" />
          </b-button>
          <div class="actions position-absolute mr-1">
            <b-button size="md" variant="success" @click="save">Save Image</b-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="confirmVisible" hide-footer title="Image Editor" size="md" centered hide-header>
      <div class="d-block text-left mt-2">
        <h4>Save Image</h4>
        <p class="h6 font-weight-normal">Do you want to create a copy of your image or you want to replace it?</p>
        {{ internal }}
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmVisible = false">Cancel</b-button>
        <b-button class="mr-1" variant="warning" @click="replace">Replace Image</b-button>
        <b-button class="" variant="primary" @click="copy">Create a copy</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mediaLibrary } from '@/api/index'
import { Cropper } from 'vue-advanced-cropper'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import 'vue-advanced-cropper/dist/style.css'
import 'vue-advanced-cropper/dist/theme.bubble.css'

export default {
  name: 'ImageEdit',
  props: [],
  components: {
    Cropper,
    VueSlider,
  },
  data() {
    return {
      visible: false,
      confirmVisible: false,
      rotation: 0,
      size: {
        width: null,
        height: null,
      },
      internal: {},
    }
  },
  watch: {
    rotation(val) {
      this.rotate(val)
    },
  },
  computed: {
    rotationRanges() {
      let data = []

      for (let i = 0; i <= 360; i++) {
        data.push(i)
      }
      return data
    },
    rotationMarks() {
      return [0, 90, 180, 270, 360]
    },
    imageUrl() {
      return `/image/${this.internal.id}/${this.internal.originalFileName}`
    },
  },
  mounted() {},
  methods: {
    defaultAreaSize({ imageSize }) {
      return {
        width: imageSize.width,
        height: imageSize.height,
      }
    },
    open(item) {
      this.internal = this.$_.cloneDeep(item)
      this.visible = true
    },
    reset() {
      this.visible = false
    },
    close() {
      this.confirmVisible = false
    },
    flip(x, y) {
      if (this.$refs.cropper.customImageTransforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y)
      } else {
        this.$refs.cropper.flip(x, y)
      }
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle)
    },
    boundaries({ cropper, imageSize }) {
      return {
        width: cropper.clientWidth,
        height: cropper.clientHeight,
      }
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor)
    },
    change(args) {
      this.size.width = Math.round(args.coordinates.width)
      this.size.height = Math.round(args.coordinates.height)
    },
    save() {
      this.confirmVisible = true
    },
    replace() {
      const self = this
      const { image, canvas } = this.$refs.cropper.getResult()

      let upload = {
        id: self.internal.id,
        fileData: canvas.toDataURL(),
        fileName: self.internal.name,
      }

      this.confirmVisible = false

      mediaLibrary.uploadImage(upload).then(res => {
        self.$emit('upload-completed')
      })
    },
    copy() {
      let self = this

      let fileExt = self.internal.originalFileName.substr(self.internal.originalFileName.lastIndexOf('.') + 1)
      let fileNameWithoutExt = self.internal.originalFileName.split('.')[0]
      let newFileName = fileNameWithoutExt + '-copy'
      if (fileExt) {
        newFileName = newFileName + '.' + fileExt
      }

      const { coordinates, canvas } = this.$refs.cropper.getResult()

      let upload = {
        folder: self.internal.folder,
        fileData: canvas.toDataURL(),
        fileName: newFileName,
      }

      this.confirmVisible = false

      mediaLibrary.uploadImage(upload).then(res => {
        self.$emit('upload-completed')
      })
    },
  },
}
</script>
<style scoped>
.size-info {
  right: 0;
  bottom: 0;
  font-size: 0.8rem;
}
.actions {
  right: 0;
}
</style>
