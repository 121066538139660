const saveSuccessful = {
  title: 'Success!',
  icon: 'BellIcon',
  text: 'Record saved successfully.',
  details: null,
  variant: 'success',
  timeout: 2500,
  position: 'top-right',
}

const publishSuccessful = {
  title: 'Success!',
  icon: 'GlobeIcon',
  text: 'Content published successfully.',
  details: null,
  variant: 'info',
  timeout: 2500,
  position: 'top-right',
}

const unpublishSuccessful = {
  title: 'Success!',
  icon: 'GlobeIcon',
  text: 'Content unpublished successfully.',
  details: null,
  variant: 'info',
  timeout: 2500,
  position: 'top-right',
}

const deleteSuccessful = {
  title: 'Success!',
  icon: 'BellIcon',
  text: 'Record successfully deleted',
  details: null,
  variant: 'success',
  timeout: 2500,
  position: 'top-right',
}

const saveError = {
  title: 'Error!',
  icon: 'BellIcon',
  text: 'An error occurred saving the record',
  details: null,
  variant: 'danger',
  timeout: 2500,
  position: 'top-right',
}

const deleteError = {
  title: 'Error!',
  icon: 'BellIcon',
  text: 'An error occurred deleting the record',
  details: null,
  variant: 'danger',
  timeout: 2500,
  position: 'top-right',
}

const infoSuccesful = {
  title: 'Success!',
  icon: 'InfoIcon',
  text: 'Success',
  details: null,
  variant: 'info',
  timeout: 2500,
  position: 'top-right',
}

const defaults = {
  saveSuccessful,
  deleteSuccessful,
  saveError,
  deleteError,
  infoSuccesful,
  publishSuccessful,
  unpublishSuccessful,
}

export default defaults

