import http from '@/http/index'

const reports = {
  async getCounters() {
    return await http.get(`/admin/reports/counters`)
  },
  async getContentStats() {
    return await http.get(`/admin/reports/content-stats`)
  },
}

export default reports
