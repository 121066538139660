import http from '@/http/index'

const accounts = {
  async login(data) {
    return await http.post('/admin/login', data)
    //return await http.post("/token", data);
  },
  async getMe() {
    return await http.get(`/admin/accounts/me`)
  },
  async getAccounts(query) {
    return await http.get(`/admin/accounts`, { params: query })
  },
  async getAccount(id) {
    return await http.get(`/admin/accounts/${id}`)
  },
  async createAccount(data) {
    return await http.post(`/admin/accounts`, data)
  },
  async updateAccount(id, data) {
    return await http.put(`/admin/accounts/${id}`, data)
  },
  async deleteAccount(id) {
    return await http.delete(`/admin/accounts/${id}`)
  },
  async changePassword(id, data) {
    return await http.post(`/admin/accounts/${id}/change-password`, data)
  },
  async getRole(id) {
    return await http.get(`/admin/roles/${id}`)
  },
  async getRoles() {
    return await http.get(`/admin/roles`)
  },
  async getPermissionSet() {
    return await http.get(`/admin/roles/permissions`)
  },
  async createRole(data) {
    return await http.post(`/admin/roles`, data)
  },
  async updateRole(id, data) {
    return await http.put(`/admin/roles/${id}`, data)
  },
  async deleteRole(id) {
    return await http.delete(`/admin/roles/${id}`)
  },
}

export default accounts
