<template>
  <b-card class="d-sm-none d-lg-block">
    <div class="m-0 h5 d-flex align-items-center text-secondary"><feather-icon icon="CodeIcon" class="mr-25" /> Code</div>
    <hr />
    <b-tabs nav-class="mb-1">
      <b-tab v-for="(code, index) in singleContentCodes" :key="'code_' + index" :title="code.language" @click="onCodeTabClick(code)">
        <codemirror
          :ref="'cmEditor' + code.language"
          :value="prepareSingleContentCode(code.snippet, model.key, content.id)"
          :options="getCodeMirrorOptions(code)"
          class="code-content"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import { singleContentCodes, prepareSingleContentCode } from '../code'
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import '@/assets/scss/atlas-codemirror.scss'
import 'codemirror/addon/scroll/simplescrollbars.js'
import 'codemirror/addon/scroll/simplescrollbars.css'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/clike/clike.js'
import 'codemirror/mode/swift/swift.js'

export default {
  name: 'ActionsBlock',
  props: ['model', 'content'],
  components: { codemirror },
  data() {
    return {
      singleContentCodes,
      prepareSingleContentCode,
    }
  },
  mounted() {},
  watch: {},
  computed: {},
  created() {},
  methods: {
    getCodeMirrorOptions(code) {
      return {
        tabSize: 4,
        mode: code.mime,
        theme: 'atlas-codemirror',
        lineNumbers: true,
        line: true,
        fixedGutter: false,
        readOnly: true,
        scrollbarStyle: 'simple',
      }
    },
    onCodeTabClick(code) {
      const self = this

      setTimeout(() => {
        self.$_.each(self.$refs['cmEditor' + code.language], editor => {
          if (editor.hasOwnProperty('codemirror')) {
            editor.codemirror.refresh()
          }
        })
      }, 10)
    },
  },
}
</script>
<style scoped>
ul,
li {
  list-style: none;
  list-style-type: none;
}
</style>
