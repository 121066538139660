<template>
  <div>
    <hr v-if="value && value.length > 0" />
    <div class="d-flex justify-content-end pb-1" v-if="internal && internal.length > 1" @click="onToggleAll">
      <b-link class="font-small-2">{{ allOpen ? 'Collapse' : 'Expand' }} All</b-link>
    </div>
    <draggable
      v-bind="dragOptions"
      v-model="internal"
      tag="div"
      handle=".drag-handle"
      @start="onDragFieldStart"
      @end="onDragFieldEnd"
      @change="onDragChanged"
      v-if="components && components.length > 0"
    >
      <div v-for="(item, index) in internal" :key="'multicomponent_' + field.key + '_' + index" class="">
        <multi-component-collapsible
          :component="getField(item['@component'].id)"
          :value="internal[index]"
          :parent-key="parentKey"
          :locale="locale"
          :errors="errors"
          :index="index"
          :dragging="drag"
          :open="isOpen(item, index)"
          @input="onInput($event, index)"
          @on-remove-item="onRemoveItem($event, index)"
        />
      </div>
    </draggable>
    <hr v-if="value && value.length > 0" />
    <div class="d-flex justify-content-center mt-1">
      <b-dropdown text="Add Component" variant="primary" v-if="components.length > 0" class="justify-content-center">
        <b-dropdown-item v-for="comp in components" :key="comp.id" @click="onAddComponent(comp)">{{ comp.name }}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>
<script>
import { contentTypes } from '@/api/index'
import draggable from 'vuedraggable'
import MultiComponentCollapsible from './MultiComponentCollapsible.vue'

// const hash = require('object-hash');

export default {
  name: 'MultiComponentField',
  props: {
    field: {
      type: Object,
      default: null,
    },
    value: {},
    nested: {
      type: Boolean,
      default: false,
    },
    parentKey: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    model: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: [],
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  components: { draggable, MultiComponentCollapsible },
  data() {
    return {
      internal: [],
      components: [],
      openPanels: [],
      drag: false,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: this.field.key,
        disabled: false,
        scrollSensitivity: 200,
        forceFallback: true,
      }
    },
    allOpen() {
      var keys = this.getAllKeys()
      for (let index = 0, len = keys.length; index < len; index++) {
        if (!this.openPanels.includes(keys[index])) {
          return false
        }
      }
      return true
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.internal = val ? val : []
      },
    },
  },
  mounted() {
    for (let index = 0; index < this.field.allowedTypes.length; index++) {
      let comp = this.$ContentTypes.findComponent(this.field.allowedTypes[index])
      this.components.push(comp)
    }
  },
  methods: {
    onAddComponent(component) {
      let element = {
        '@component': {
          id: component.id,
          key: component.key,
        },
      }

      let length = this.internal.push(element)

      let key = this.getElementName(element, length - 1)

      this.$emit('input', [...this.internal])

      this.openPanels.push(key)
    },
    onRemoveItem(item, index) {
      this.internal.splice(index, 1)
      this.$emit('input', [...this.internal])
    },
    getField(componentId) {
      return this.$_.find(this.components, c => c.id == componentId)
    },
    onDragChanged(e) {
      this.$emit('input', [...this.internal])
    },
    onDragFieldEnd(e) {
      this.drag = false
    },
    onDragFieldStart(e) {
      this.drag = true
    },

    onInput(event, index) {
      //console.log(event)
      const payload = [...this.internal]
      payload[index] = event
      this.$emit('input', payload)
    },
    getAllKeys() {
      const keys = []
      for (let index = 0, len = this.internal.length; index < len; index++) {
        keys.push(this.getElementName(this.internal[index], index))
      }
      return keys
    },
    isOpen(item, index) {
      return this.openPanels.includes(this.getElementName(item, index))
    },
    onToggleAll() {
      var keys = this.getAllKeys()

      if (this.allOpen) {
        this.openPanels = []
      } else {
        for (let index = 0, len = keys.length; index < len; index++) {
          if (!this.openPanels.includes(keys[index])) {
            this.openPanels.push(keys[index])
          }
        }
      }
    },
    getElementName(element, index) {
      return `mc-${element['@component'].id}-${index}`
    },
  },
}
</script>
<style scoped>
.sortable-ghost {
  opacity: 0;

  margin: 10px 15px;

  border-left: 3px solid blue;
}
.chosen {
  opacity: 0;
  background: blue;
}
.sortable-drag {
  opacity: 0;
  /* border-left: 3px solid red; */
}
.sortable-chosen {
  opacity: 0;
  /* padding: 5px 5px 0px 5px; */
  /* border-left: 3px solid green; */
}
.sortable-fallback {
  opacity: 0;
  /* border-left: 3px solid blue; */
}
</style>
