<template>
  <div>
    <page-loader v-if="loading" type="content" :fluid="true" />
    <portal to="nav-bar-left-column" v-if="!loading"> <page-title :title="model.name" :subtitle="model.description"/></portal>
    <portal to="nav-bar-right-column" v-if="!loading">
      <div class="d-flex align-items-center">
        <span v-if="formErrors" class="mr-1 d-flex align-items-center text-danger"
          ><feather-icon icon="AlertCircleIcon" class="m-0 p-0 ml-1 mr-25" />There are errors on the page that prevent to save changes</span
        >

        <b-button :variant="publishButtonVariant" @click="onSelectPublishing" v-if="isPublishStagesVisibile" class="mr-1" :disabled="isPublishDisabled">
          <feather-icon icon="UploadIcon" size="14" />
          <span class="ml-50">Publish</span>
        </b-button>
        <b-button
          :variant="publishButtonVariant"
          @click="onSetStageForContent(contents[0].id, 'published')"
          v-if="isPublishContentVisibile"
          class="mr-1"
          :disabled="isPublishDisabled"
        >
          <feather-icon icon="UploadIcon" size="14" />
          <span class="ml-50">Publish</span>
        </b-button>
        <b-button
          :variant="unpublishButtonVariant"
          @click="onSetStageForContent(contents[0].id, 'unpublished')"
          v-if="isUnpublishContentVisibile"
          class="mr-1"
          :disabled="isPublishDisabled"
        >
          <feather-icon icon="DownloadIcon" size="14" />
          <span class="ml-50">Unpublish</span>
        </b-button>
        <b-button :variant="saveButtonVariant" :disabled="!(contents.length > 0 && isDirty && !isEmptyModel)" @click="onSave">Save</b-button>
      </div>
    </portal>
    <div class="loading-hack" />
    <form-alert :errors="displayErrors" />
    <b-tabs v-if="!loading" nav-class="mb-1" @activate-tab="onActivateTab" @changed="onChangeTab" v-model="activeTab" ref="contentLocaleTabs">
      <b-tab v-for="content in orderedContents" :key="content.id" @click="onClickLocaleTab(content)">
        <template #title>
          <flag-icon :country-code="cultures.getCultureByName(content.locale).isoCountryCode2" class="mr-1" />
          {{ cultures.getCultureByName(content.locale).isoCode3.toUpperCase() }}
          <!-- <span class="pl-25 d-flex align-items-center"><feather-icon icon="AlertCircleIcon" class="m-0 p-0 text-danger"/></span> -->
        </template>
        <div class="d-flex">
          <div class="flex-grow-1 pb-0 mb-0">
            <div class="justify-content-start" v-if="!isEmptyModel">
              <validation-observer :ref="'contentsForm_' + content.locale">
                <fieldset :disabled="!$acl.can(updateContentsPermission)">
                  <b-form @submit.prevent v-on:keyup.enter="onFormEnterKey">
                    <div v-for="field in fields" :key="field.key">
                      <field-editor
                        v-model="content.attributes[field.key]"
                        :errors="errors"
                        :field="field"
                        :locale="content.locale"
                        @cross-locale-value-change="onCrossLocaleValueChange"
                      />
                    </div>
                  </b-form>
                </fieldset>
              </validation-observer>
            </div>
            <div class="d-flex justify-content-center align-items-center pt-1 flex-column h-75" v-else>
              <b-img fluid :src="emptyModelImageUrl" />
              <h2 class="font-weight-normal mb-2 mt-2">The Model has no fields available.</h2>
            </div>
          </div>
          <div class="w-25 ml-1">
            <div v-if="currentContent">
              <InfoBlock :model="model" :cultures="cultures" :content="currentContent" :contents="contents" @add-locale="onAddContentLocale" />
              <LinksBlock :model="model" :content="currentContent" v-if="model.properties.links && model.properties.links.length" />
              <ActionsBlock
                :model="model"
                @duplicate-content="onDuplicateContent"
                @delete-content="confirmDeleteContent"
                @delete-locale-content="confirmDeleteSingleContent"
              />
              <CodesBlock :model="model" :content="currentContent" />

              <div class="p-0 d-sm-block d-md-none">
                <feather-icon icon="ChevronLeftIcon" class="cursor-pointer" />
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <b-modal id="deleteConfirmModal" hide-footer :title="model.name" size="md" centered hide-header @hidden="resetDeleteModal">
      <div class="d-block text-left mt-2">
        <h4>Delete Content</h4>
        <p class="h6 font-weight-normal" v-if="deleteType == 'all'">Do you want to delete the content?</p>
        <p class="h6 font-weight-normal" v-if="deleteType == 'single'">
          Do you want to delete the content locale {{ currentContent ? cultures.getCultureByName(currentContent.locale).isoCode3.toUpperCase() : '' }}?
        </p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="$bvModal.hide('deleteConfirmModal')">Cancel</b-button>
        <b-button class="" variant="danger" @click="deleteContent">Delete</b-button>
      </div>
    </b-modal>
    <b-modal id="publishModal" hide-footer :title="model.name" size="lg" centered hide-header @hidden="resetStagesModal">
      <div class="d-block text-left mt-2">
        <h4>Publish {{ model.name }}</h4>
        <hr />
        <p>
          Please select content to be published or deselect those to unpublish
        </p>
        <div class="mt-2">
          <b-row>
            <b-col cols="12" md="6" v-for="(content, index) in contentStages" :key="`publish_content_${content.id}_${index}`" class="mb-1">
              <b-form-checkbox class="custom-control-success mt-0" switch inline v-model="content.stage" value="published" unchecked-value="unpublished">
                <flag-icon :country-code="cultures.getCultureByName(content.locale).isoCountryCode2" />
                {{ cultures.getCultureByName(content.locale).displayName }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="$bvModal.hide('publishModal')">Cancel</b-button>
        <b-button class="" variant="primary" @click="onSaveStage">Confirm</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { contents } from '@/api/index'
import cultures from '@/libs/cultures-db'
import FlagIcon from '@/components/FlagIcon/Index.vue'
import DefaultNotifications from '@/components/Notification/default'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import LinksBlock from './LinksBlock.vue'
import InfoBlock from './InfoBlock.vue'
import ActionsBlock from './ActionsBlock.vue'
import CodesBlock from './CodesBlock.vue'

export default {
  name: 'EditContent',
  props: ['model'],
  components: {
    FlagIcon,
    FeatherIcon,
    LinksBlock,
    InfoBlock,
    ActionsBlock,
    CodesBlock,
  },
  data() {
    return {
      cultures,
      formErrors: false,
      displayErrors: [],
      errors: [],
      isDirty: false,
      contents: [],
      originals: [],
      contentStages: [],
      currentContent: null,
      deleteType: 'single',
      activeTab: 0,
      loading: false,
      emptyModelImage: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  mounted() {},
  watch: {
    contents: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.originals)
        this.$emit('dirty-change', this.isDirty)
      },
    },
  },
  computed: {
    ...mapGetters(['Settings']),
    id() {
      return this.$route.params.id
    },

    fields() {
      return this.model.attributes
    },
    orderedContents() {
      let self = this
      let locales = self.settingsLocales
      return self.$_.sortBy(self.contents, c => {
        return self.$_.findIndex(locales, l => l.culture == c.locale)
      })
    },
    settingsLocales() {
      let orderedLocales = this.$_.orderBy(this.Settings.locales, ['isDefault', 'locale'], ['desc', 'asc'])

      let locales = []

      this.$_.each(orderedLocales, l => {
        locales.push(this.getLocaleData(l.locale))
      })

      return locales
    },
    updateContentsPermission() {
      return `contents.${this.model.id}.update`
    },
    emptyModelImageUrl() {
      return this.emptyModelImage
    },
    isEmptyModel() {
      return !this.fields || this.fields.length == 0
    },
    saveButtonVariant() {
      if (!(this.contents.length > 0 && this.isDirty && !this.isEmptyModel)) {
        return 'outline-secondary'
      }

      return 'primary'
    },
    publishButtonVariant() {
      if (this.isPublishDisabled) {
        return 'outline-secondary'
      }

      return 'outline-success'
    },
    unpublishButtonVariant() {
      if (this.isPublishDisabled) {
        return 'outline-secondary'
      }

      return 'outline-warning'
    },
    isPublishStagesVisibile() {
      return this.model.enableStageMode && this.contents.length > 1
    },
    isPublishDisabled() {
      return this.isDirty
    },
    isPublishContentVisibile() {
      return this.model.enableStageMode && (this.contents.length == 1 || !this.model.localizable) && this.contents[0].stage == 'unpublished'
    },
    isUnpublishContentVisibile() {
      return this.model.enableStageMode && (this.contents.length == 1 || !this.model.localizable) && this.contents[0].stage == 'published'
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData(noLoading) {
      const self = this

      if (!noLoading) self.loading = true

      let tempContents = []

      let mainContent = (await contents.getContent(this.model.key, this.id, { resolve: 'media,mediagallery' })).data

      tempContents.push(mainContent)

      if (mainContent.locales) {
        for (let index = 0; index < mainContent.locales.length; index++) {
          let existLocale = this.$_.findIndex(self.settingsLocales, c => c.culture == mainContent.locales[index].locale)
          if (existLocale >= 0) {
            let lc = (await contents.getContent(this.model.key, mainContent.locales[index].id, { resolve: 'media,mediagallery' })).data
            tempContents.push(lc)
          }
        }
      }

      self.loading = false

      self.prepareContents(tempContents)
      self.prepareContentStages()
    },
    prepareContents(contents) {
      const self = this

      self.originals = contents
      self.contents = self.$_.cloneDeep(contents)

      if (!self.currentContent) {
        self.currentContent = self.$_.find(self.contents, c => c.id == self.id)
      }
    },
    prepareContentStages() {
      const self = this
      self.contentStages = self.originals.map(m => ({
        id: m.id,
        locale: m.locale,
        stage: m.stage,
      }))
    },
    async onFormEnterKey() {
      const self = this
      if (self.contents.length > 0 && self.isDirty && !self.isEmptyModel) {
        await self.onSave()
      }
    },
    onSelectPublishing() {
      this.$bvModal.show('publishModal')
    },
    async onSaveStage() {
      const self = this

      let tempContents = []

      for (let index = 0; index < self.contentStages.length; index++) {
        let current = self.$_.find(self.contents, c => c.id == self.contentStages[index].id)
        if (current.stage != self.contentStages[index].stage) {
          let fx = self.contentStages[index].stage == 'published' ? contents.publishContent : contents.unpublishContent

          await fx(self.model.key, self.contentStages[index].id)

          let content = await contents.getContent(self.model.key, self.contentStages[index].id, { resolve: 'media,mediagallery' })

          tempContents.push(content.data)
        } else {
          tempContents.push(current)
        }
      }

      self.$bvModal.hide('publishModal')

      self.currentContent = null
      self.prepareContents(tempContents)
      self.prepareContentStages()
    },
    async onSetStageForContent(id, stage) {
      const self = this

      const index = self.$_.findIndex(self.contentStages, c => c.id == id)

      self.contentStages[index].stage = stage

      self.onSaveStage()
    },

    resetStagesModal() {
      this.prepareContentStages()
    },
    async onSave() {
      var self = this

      var validations = await self.validateForms()

      if (!self.formErrors) {
        let payload = {
          contents: self.contents.map(c => {
            return { id: c.id, attributes: c.attributes }
          }),
        }

        contents
          .updateLocaleSet(this.model.key, payload)
          .then(res => {
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.originals = self.$_.cloneDeep(self.contents)
            self.contents = self.$_.cloneDeep(self.originals)
            self.resetErrors()
          })
          .catch(err => {
            self.formErrors = true
            if (err.response.status == 422) {
              self.handleServerErrorMessages(err.response.data)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        //console.log(validations)
      }
    },
    async onDuplicateContent() {
      var self = this

      contents
        .duplicateContentAll(this.model.key, this.currentContent.id)
        .then(res => {
          this.$emit('dirty-change', false)
          self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)

          if (Array.isArray(res.data.result)) {
            this.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: res.data.result[0] } })
          } else {
            this.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: res.data.result } })
          }
        })
        .catch(err => {
          self.formErrors = true
          if (err.response.stage == 422) {
            self.errors = err.response.data.errors
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
          }
        })
    },
    confirmDeleteContent() {
      this.deleteType = 'all'
      this.$bvModal.show('deleteConfirmModal')
    },
    confirmDeleteSingleContent() {
      this.deleteType = 'single'
      this.$bvModal.show('deleteConfirmModal')
    },
    async deleteContent() {
      const self = this
      if (self.deleteType == 'single' && self.contents.length > 1) {
        await contents.deleteContent(self.model.key, self.currentContent.id, false)

        const contentIndex = self.contents.findIndex(p => p.id === self.currentContent.id)
        self.contents.splice(contentIndex, 1)

        const originalIndex = self.originals.findIndex(p => p.id === self.currentContent.id)
        self.originals.splice(originalIndex, 1)
        self.resetErrors()

        if (self.contents.findIndex(p => p.id === self.currentContent.id) < 0 && self.currentContent.id == self.id) {
          self.isDirty = false
          self.$router.push({ name: 'atlas_edit_' + self.model.key, params: { id: self.contents[0].id } })
        } else {
          self.$refs.contentLocaleTabs.tabs[0].activate()
          self.prepareContentStages()
        }
      } else {
        await contents.deleteContent(self.model.key, self.currentContent.id, true)

        self.isDirty = false
        self.resetErrors()

        if (self.model.isSingle) {
          self.$router.push({ name: 'atlas_single_' + self.model.key })
        } else {
          self.$router.push({ name: 'atlas_list_' + self.model.key })
        }
      }

      self.$bvModal.hide('deleteConfirmModal')

      self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
    },
    async validateForms() {
      let self = this

      self.formErrors = false

      const validations = []

      for (let index = 0; index < self.contents.length; index++) {
        const v = await self.$refs['contentsForm_' + self.contents[index].locale][0].validateWithInfo()
        if (!v.isValid) {
          self.formErrors = true
          validations.push(v)
        }
      }

      return validations
    },
    onAddContentLocale(locale) {
      let self = this

      contents
        .createContentTranslation(self.model.key, self.id, locale)
        .then(res => {
          contents.getContent(self.model.key, res.data.result, { resolve: 'media,mediagallery' }).then(content => {
            const newContent = content.data

            self.originals.push(self.$_.cloneDeep(newContent))
            self.contents.push(newContent)

            self.syncCrossLocaleData(newContent, self.currentContent)
            self.currentContent = self.$_.find(self.contents, c => c.id == newContent.id)

            self.prepareContentStages()
            self.resetErrors()
          })
        })
        .catch(err => {
          self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
        })
    },
    onClickLocaleTab(content) {
      // this.$router.push({
      //   name: 'atlas_edit_' + this.model.key,
      //   params: { id: content.id },
      // })
    },
    onChangeTab(c, p) {
      let tab = this.orderedContents.findIndex(p => p.id === this.currentContent.id)
      this.activeTab = tab
    },
    async onActivateTab(newTab) {
      this.currentContent = this.orderedContents[newTab]
    },
    onCrossLocaleValueChange(evt) {
      // console.log(evt)
      const self = this
      const path = evt.path
      const locale = evt.locale
      self.contents.forEach((language, i) => {
        if (language.locale !== locale) {
          self.contents[i].attributes[path] = self.$_.clone(evt.event)
        }
      })
    },
    syncCrossLocaleData(newContent, content) {
      for (var index in this.model.attributes) {
        if (this.model.attributes[index].localizable === false) {
          if (newContent.attributes.hasOwnProperty(this.model.attributes[index].key)) {
            newContent.attributes[this.model.attributes[index].key] = content.attributes[this.model.attributes[index].key]
          }
        }
      }
    },
    getLocaleData(locale) {
      return this.cultures.getCultureByName(locale)
    },
    resetDeleteModal() {
      this.deleteType = 'single'
    },
    prepareToExit() {
      if (this.isDirty) {
        const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
        if (answer) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    checkDirtyDataOnExit(e) {
      if (!this.isDirty) return
      e.preventDefault()
      e.returnValue = ''
    },
    resetErrors() {
      this.displayErrors = []
      this.errors = []
    },
    handleServerErrorMessages(response) {
      const uniqueErrors = []
      const structuredErrors = []

      for (let i = 0; i < response.errors.length; i++) {
        let serverError = response.errors[i]

        serverError.paths = []

        let error = uniqueErrors.find(e => e.message == serverError.message)

        if (!error) {
          uniqueErrors.push(serverError)
        }

        let errorPropertyPath = serverError.propertyName.split('.')

        let index = 0

        for (let pi = 0; pi < errorPropertyPath.length; pi++) {
          let path = errorPropertyPath[pi]
          if (path.startsWith('[') && path.endsWith(']')) {
            index = path.replace(/^\[+|\]+$/g, '')
          } else {
            if (pi > 0) {
              serverError.paths.push({ path: serverError.paths[serverError.paths.length - 1].path + '.' + path, index: index })
            } else {
              serverError.paths.push({ path: path, index: index })
            }
          }
        }

        structuredErrors.push(serverError)
      }

      this.displayErrors = uniqueErrors
      this.errors = structuredErrors
    },
  },
}
</script>
<style>
/* required class */
.code-viewer {
  background: #2d2d2d;
  color: #fff !important;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
.code-content .CodeMirror {
  font-size: 0.9rem;
  height: 10rem;
}
</style>
