<template>
  <b-card class="d-sm-none d-lg-block">
    <div class="m-0 h5 d-flex align-items-center justify-content-between text-info text-darken-2">
      <div><feather-icon icon="InfoIcon" class="mr-25" /> System Information</div>
      <div>
        <b-dropdown text="Add Locale" variant="primary" size="sm" :right="true" v-if="model.localizable && availableLocales.length > 0">
          <b-dropdown-item v-for="al in availableLocales" :key="'al_' + al.culture" @click="$emit('add-locale', al.culture)">
            <flag-icon :country-code="cultures.getCultureByName(al.culture).isoCountryCode2" class="mr-1" />
            {{ cultures.getCultureByName(al.culture).displayName }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <hr />
    <table class="mt-2 mt-xl-0">
      <tr>
        <td class="pb-50 pr-1">
          <span class="font-weight-bold">Language</span>
        </td>
        <td class="pb-50">
          <flag-icon :country-code="cultures.getCultureByName(content.locale).isoCountryCode2" />
          {{ cultures.getCultureByName(content.locale).displayName }}
        </td>
      </tr>
      <tr v-if="model.enableStageMode">
        <td class="pb-50 pr-1">
          <span class="font-weight-bold">Stage</span>
        </td>
        <td class="pb-50">
          <b-badge pill v-if="content.stage == 'published'" variant="success">
            Published
          </b-badge>
          <b-badge pill v-if="content.stage == 'unpublished'" variant="warning">
            Unpublished
          </b-badge>
        </td>
      </tr>
      <tr>
        <td class="pb-50 pr-1">
          <span class="font-weight-bold">ID</span>
        </td>
        <td class="pb-50">
          {{ content.id }}
        </td>
      </tr>
      <tr>
        <td class="pb-50 pr-1">
          <span class="font-weight-bold">Created at</span>
        </td>
        <td class="pb-50">
          {{ content.createdAt }}
        </td>
      </tr>
      <tr>
        <td class="pb-50 pr-1">
          <span class="font-weight-bold">Modified at</span>
        </td>
        <td class="pb-50">
          {{ content.modifiedAt }}
        </td>
      </tr>
    </table>
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import FlagIcon from '@/components/FlagIcon/Index.vue'

export default {
  name: 'InfoBlock',
  props: ['model', 'content', 'contents', 'cultures'],
  components: { FlagIcon },
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters(['Settings']),
    settingsLocales() {
      let orderedLocales = this.$_.orderBy(this.Settings.locales, ['isDefault', 'locale'], ['desc', 'asc'])

      let locales = []

      this.$_.each(orderedLocales, l => {
        locales.push(this.cultures.getCultureByName(l.locale))
      })

      return locales
    },
    availableLocales() {
      let locales = []

      this.$_.each(this.settingsLocales, l => {
        let index = this.$_.findIndex(this.contents, c => c.locale == l.culture)

        if (index < 0) {
          locales.push(l)
        }
      })

      return locales
    },
  },
  created() {},
  methods: {},
}
</script>
<style scoped>
ul,
li {
  list-style: none;
  list-style-type: none;
}
</style>
