<template>
  <div>
    <div v-if="$acl.can('admin.accounts.read')">
      <page-loader v-if="loading" type="list" :fluid="true" />
      <portal to="nav-bar-left-column">
        <page-title title="Accounts" subtitle="Users that can access the admin interface" />
      </portal>
      <b-container fluid>
        <b-card no-body>
          <portal to="nav-bar-right-column">
            <new-account @created="onCreatedAccount" :roles="roles" />
          </portal>
          <div v-if="!loading">
            <b-card-body>
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="flex-fill">
                  <b-form inline @submit.prevent>
                    <div class="d-flex justify-content-between w-100">
                      <b-input-group class="w-25">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="SearchIcon" />
                        </b-input-group-prepend>
                        <b-form-input placeholder="Search" @keyup.enter="onTextSearch" v-model="searchText" />
                      </b-input-group>
                    </div>
                  </b-form>
                </div>
              </div>
            </b-card-body>
            <b-table
              striped
              hover
              responsive
              class="position-relative"
              :current-page="currentPage"
              :items="accounts"
              :fields="columns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :sticky-header="false"
              @sort-changed="onSortChanged"
              no-local-sorting
            >
              <template #head($selection)="data">
                <b-form-checkbox @change="onSelectAllAccounts" />
              </template>
              <template #cell($selection)="data">
                <b-form-checkbox @change="onSelectAccount($event, data.item.id)" :checked="isAccountSelected(data.item.id)" />
              </template>
              <template #cell(createdAt)="data">
                <span v-html="data.value"></span>
              </template>
              <template #cell(modifiedAt)="data">
                <span v-html="data.value"></span>
              </template>
              <template #cell(lastName)="data">
                <b-link @click="onEditAccount(data.item.id)">{{ data.value }}</b-link>
              </template>
              <template #cell(isActive)="data">
                <b-badge :variant="'light-' + (data.item.isActive ? 'success' : 'dark')">{{ data.item.isActive ? 'active' : 'inactive' }}</b-badge>
              </template>
              <template #cell($actions)="data">
                <b-button variant="outline-secondary" size="sm" class="btn-icon rounded-circle" @click="onEditAccount(data.item.id)">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-dropdown variant="link" no-caret toggle-class="p-0" right :dropup="true" class="ml-1">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item @click="onChangePasswordModal(data.item.id)" v-if="$acl.can('admin.accounts.changepassword')">
                    <feather-icon icon="LockIcon" class="" />
                    <span class="align-middle ml-50">Change Password</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onDeleteConfirm(data.item.id)" v-if="$acl.can('admin.accounts.delete')">
                    <feather-icon icon="TrashIcon" class="text-danger darken-2" />
                    <span class="align-middle ml-50 text-danger text-darken-2">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" @change="onRowsPerPageChanged" />
              </b-form-group>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-if="totalPages > 1"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                  @change="onPageChanged"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </div>
        </b-card>
      </b-container>
      <edit-account ref="EditAccountModal" :roles="roles" @modified="onModifiedAccount" />
      <b-modal id="deleteConfirmModal" hide-footer title="User" size="md" centered hide-header v-model="confirmDelete">
        <div class="d-block text-left mt-2">
          <h4>Delete Account</h4>
          <p class="h6 font-weight-normal">Do you want to delete the account?</p>
        </div>
        <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
          <b-button class="mr-auto" variant="outline-secondary" @click="confirmDelete = false">Cancel</b-button>
          <b-button class="" variant="danger" @click="onDeleteAccount">Delete</b-button>
        </div>
      </b-modal>
      <b-modal
        id="changePasswordModal"
        hide-footer
        title="Change Password"
        size="md"
        centered
        hide-header
        v-model="changePassword"
        @hidden="onResetChangePassword"
      >
        <div class="d-block text-left mt-2">
          <form-alert :errors="changePasswordServerErrors" />
          <validation-observer ref="changePasswordForm">
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <text-input
                    v-model="newPassword.password"
                    placeholder="Password"
                    label="New Password"
                    validation-rules="required"
                    type="password"
                    vid="password"
                  />
                </b-col>

                <b-col cols="12">
                  <text-input
                    v-model="newPassword.confirm"
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    validation-rules="required|confirmpassword:password"
                    type="password"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
        <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
          <b-button class="mr-auto" variant="outline-secondary" @click="changePassword = false">Cancel</b-button>
          <b-button class="" variant="danger" @click="onChangePassword">Submit</b-button>
        </div>
      </b-modal>
    </div>
    <div v-else>
      <forbidden />
    </div>
  </div>
</template>
<script>
import NewAccount from './components/NewAccount.vue'
import EditAccount from './components/EditAccount.vue'
import DefaultNotifications from '@/components/Notification/default'
import { accounts } from '@/api/index'
import Forbidden from '@/components/Forbidden/Index.vue'

export default {
  name: 'Accounts',
  components: { NewAccount, EditAccount, Forbidden },
  data() {
    return {
      roles: [],
      changePasswordServerErrors: [],
      changePassword: false,
      confirmDelete: false,
      accounts: [],
      selectedAccounts: [],
      selectedAccountId: 0,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      searchText: '',
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      totalPages: 1,
      loading: false,
      newPassword: {
        password: '',
        confirm: '',
      },
      columns: [
        // {
        //   key: '$selection',
        //   label: '',
        //   sortable: false,
        //   tdClass: 'icon-cell',
        // },
        {
          key: 'lastName',
          label: 'Name',
          sortable: true,
          formatter: (value, key, item) => {
            return `${item.firstName} ${item.lastName}`
          },
        },
        {
          key: 'username',
          label: 'Username',
          value: 'username',
          sortable: true,
        },
        {
          key: 'isActive',
          label: 'Active',
          value: 'isActive',
          sortable: true,
        },
        {
          key: 'roles',
          label: 'Roles',
          value: 'roles',
          sortable: false,
          formatter: (value, key, item) => {
            let self = this
            let roles = []
            self.$_.forEach(value, function(id) {
              let names = self.$_.filter(self.roles, function(r) {
                return r.id === id
              })

              if (names) {
                roles.push(names[0].name)
              } else {
                roles.push(id)
              }
            })

            return roles.join(',')
          },
        },
        {
          key: 'createdAt',
          label: 'Created At',
          value: 'createAt',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: 'modifiedAt',
          label: 'Modified At',
          value: 'modifiedAt',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: '$actions',
          label: '',
          tdClass: 'action-cell-medium',
        },
      ],
    }
  },
  watch: {
    $route(to, from) {
      this.fetchData(to.query)
    },
  },
  computed: {},
  mounted() {},
  created() {
    let self = this
    self.loading = true
    accounts.getRoles().then(res => {
      self.roles = res.data
      self.fetchData()
    })
  },
  methods: {
    async fetchData() {
      const self = this
      const query = this.getCurrentFilters()

      await accounts.getAccounts(query).then(res => {
        self.accounts = res.data.data
        self.totalRows = res.data.metadata.count
        self.totalPages = res.data.metadata.totalPages
        self.currentPage = res.data.metadata.currentPage == 0 ? 1 : res.data.metadata.currentPage
        self.loading = false
      })
    },
    getCurrentFilters() {
      return {
        page: this.currentPage,
        size: this.perPage,
        search: this.searchText,
        sort: `${this.sortBy}|${this.sortDirection}`,
      }
    },
    onTextSearch(e) {
      this.currentPage = 1
      this.fetchData()
    },
    onLocaleChange(e) {
      this.currentPage = 1
      this.fetchData()
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy
      this.sortDirection = e.sortDesc ? 'desc' : 'asc'
      this.fetchData()
    },
    onPageChanged(e) {
      this.currentPage = e
      this.fetchData()
    },
    onRowsPerPageChanged(e) {
      this.perPage = e
      this.fetchData()
    },
    onSelectAllAccounts(val) {
      if (val) {
        this.selectedAccounts = this.accounts.map(c => c.id)
      } else {
        this.selectedAccounts = []
      }
    },
    onSelectAccount(val, id) {
      let index = this.$_.findIndex(this.selectedAccounts, function(c) {
        return c == id
      })

      if (val) {
        if (index < 0) {
          this.selectedAccounts.push(id)
        }
      } else {
        if (index >= 0) {
          this.selectedAccounts.splice(index, 1)
        }
      }
    },
    isAccountSelected(id) {
      let index = this.$_.findIndex(this.selectedAccounts, function(c) {
        return c == id
      })

      return index >= 0
    },
    onCreatedAccount() {
      this.currentPage = 1
      this.search = ''
      this.fetchData()
    },
    onModifiedAccount() {
      this.fetchData()
    },
    onEditAccount(id) {
      this.$refs.EditAccountModal.editAccount(id)
    },
    onDeleteConfirm(id) {
      this.selectedAccountId = id
      this.confirmDelete = true
    },
    onDeleteAccount() {
      const self = this
      accounts
        .deleteAccount(self.selectedAccountId)
        .then(res => {
          self.serverErrors = []
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.confirmDelete = false
          self.page = 1
          self.fetchData()
        })
        .catch(err => {
          self.formErrors = true
          if (err.response.status == 422) {
            self.serverErrors = err.response.data.errors
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          }
        })
    },
    onResetChangePassword() {
      this.newPassword = {
        password: '',
        confirm: '',
      }
    },
    async onChangePasswordModal(id) {
      this.selectedAccountId = id
      this.changePassword = true
    },
    async onChangePassword() {
      const self = this

      const validation = await self.$refs.changePasswordForm.validateWithInfo()

      if (validation.isValid) {
        const data = {
          id: self.selectedAccountId,
          password: self.newPassword.password,
        }

        accounts
          .changePassword(self.selectedAccountId, data)
          .then(res => {
            self.changePasswordServerErrors = []
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.changePassword = false
          })
          .catch(err => {
            self.formErrors = true
            if (err.response.status == 422) {
              self.changePasswordServerErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      }
    },
  },
}
</script>
<style lang="scss">
.inner-tab-title-class {
  .nav-link {
    color: #6e6b7b !important;
  }
  .nav-link.active {
    font-weight: 500;
  }
  .nav-link:after {
    background: linear-gradient(30deg, #6e6b7b, #6e6b7b) !important;
  }
}
</style>
