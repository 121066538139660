<template>
  <div @mouseenter="hover = true" @mouseleave="hover = false" v-if="media != null" class="drag-handle item-container d-flex flex-column mb-1">
    <b-card no-body>
      <div class="preview d-flex rounded-top">
        <div class="d-flex w-100 h-100 position-relative" :class="{ 'cursor-move': gallery }">
          <div class="d-flex align-items-center justify-content-center w-100 h-100 position-absolute" v-if="imageLoading">
            <b-spinner label="Loading..." class="align-middle" />
          </div>
          <div v-if="media.type == 'image'" class="d-flex bg-img-empty w-100 h-100">
            <v-lazy-image
              v-b-tooltip.hover.v-secondary.top
              :src="mediaUrl"
              :style="imageBoxStyle"
              :title="media.originalFileName"
              :use-picture="false"
              class="m-auto"
              @load="imageLoading = false"
            />
          </div>
          <div class="m-auto" v-else>
            <img
              :src="require(`@/assets/file-icons/svg/${fileIcon.getIcon(media.originalFileName)}`)"
              height="80"
              v-b-tooltip.hover.v-secondary.top
              :title="media.originalFileName"
            />
          </div>
        </div>
      </div>
      <b-card-body class="info d-flex align-items-center justify-content-center p-1">
        <div class="text-center">
          <b-link variant="link" class="text-dark" style="line-height:15px" size="md" @click="$emit('open')"
            ><feather-icon icon="EyeIcon" size="18" class="mr-1"
          /></b-link>
          <b-link variant="link" class="text-danger" style="line-height:15px" size="md" @click="$emit('delete')"><feather-icon icon="XIcon" size="18"/></b-link>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import fileIcon from '@/assets/file-icons/index.js'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
export default {
  props: ['media', 'boxSize', 'gallery'],
  name: 'MediaFieldPreview',
  components: { BCardActions },
  data() {
    return {
      hover: false,
      fileIcon,
      imageLoading: this.media.type == 'image',
    }
  },
  watch: {},
  computed: {
    imageBoxSize() {
      return {
        'min-width': this.boxSize + 'px',
        'min-height': this.boxSize + 'px',
      }
    },
    imageBoxStyle() {
      if (this.imageLoading) {
        return { opacity: 0 }
      } else {
        return { display: 1 }
      }
    },
    mediaUrl() {
      return `${this.media.url}?width=160&height=120&ranchor=center&rmode=boxpad` //&rmode=pad&format=png`
    },
  },
  methods: {},
}
</script>
