<template>
  <div>
    <b-modal
      ref="mediaInfoModal"
      dialog-class="media-library-media-info modal-full-screen"
      :no-close-on-backdrop="false"
      :no-close-on-esc="false"
      :hide-header="true"
      :hide-footer="true"
      v-model="visible"
      centered
      size="lg"
      ok-variant="outline-secondary"
      ok-only
      ok-title="Done"
      @hidden="reset"
    >
      <div class="media-info-container d-flex h-100 align-items-stretch flex-row">
        <div class="flex-grow-1 main-panel">
          <div class="d-flex flex-column h-100">
            <div class="header justify-content-center">
              <!-- <h4 class="m-0 text-dark">{{ internal.originalFileName }}</h4> -->
              <b-link variant="link" class="text-dark mr-1" style="line-height:15px" size="md" @click="edit"
                ><feather-icon icon="CropIcon" size="14" /> Edit</b-link
              >
              <!-- <b-link variant="link" class="text-dark mr-1" style="line-height:15px" size="md" @click="close" v-if="$acl.can('medialibrary.files.replace')"
                ><feather-icon icon="UploadCloudIcon" /> Replace</b-link
              >
              <b-link variant="link" class="text-dark mr-1" style="line-height:15px" size="md" @click="close" v-if="$acl.can('medialibrary.files.rename')"
                ><feather-icon icon="TypeIcon" /> Rename</b-link
              > -->
              <b-link
                v-clipboard:copy="internal.url"
                v-clipboard:success="onCopyLinkSuccess"
                v-clipboard:error="onCopyLinkError"
                variant="link"
                class="text-dark mr-1"
                style="line-height:15px"
                size="md"
                ><feather-icon icon="LinkIcon" /> Copy Url</b-link
              >
            </div>
            <div class="main p-2 bg-black overflow-hidden">
              <div class="preview">
                <div class="img-container" v-resize @resize="onResizeMediaContainer">
                  <img :src="mediaUrl" :style="{ width: imageSize.width + 'px', height: imageSize.height + 'px' }" v-if="isImage" />
                  <img
                    :src="require(`@/assets/file-icons/svg/${fileIcon.getIcon(internal.originalFileName)}`)"
                    height="250"
                    style="max-height:250px"
                    v-if="!isImage"
                  />
                </div>
              </div>
            </div>
            <div class="footer justify-content-center align-items-center">
              <h4 class="m-0 text-dark">{{ internal.originalFileName }}</h4>
            </div>
          </div>
        </div>
        <div class="side-panel">
          <div class="d-flex flex-column h-100">
            <div class="header justify-content-between pr-1">
              <h5 class="m-0 text-uppercase text-da rk">MEDIA INFORMATION</h5>
              <b-button variant="link" class="text-dark" @click="close"><feather-icon icon="XIcon" size="22"/></b-button>
            </div>
            <div class="main">
              <div class="border-bottom p-1 bg-light">
                <h5 class="m-0 text-uppercase text-dark">System</h5>
              </div>
              <div class="p-1">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">ID</span>
                    </th>
                    <td class="pb-50">
                      {{ internal.id }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Folder</span>
                    </th>
                    <td class="pb-50">
                      {{ internal.folder }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Created at</span>
                    </th>
                    <td class="pb-50">
                      {{ createdAt }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Modified at</span>
                    </th>
                    <td class="pb-50">
                      {{ modifiedAt }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Type</span>
                    </th>
                    <td class="pb-50">
                      {{ internal.type }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Size</span>
                    </th>
                    <td class="pb-50">
                      {{ mediaSize }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Ext.</span>
                    </th>
                    <td class="pb-50">
                      {{ internal.format }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Dim.</span>
                    </th>
                    <td class="pb-50">
                      {{ mediaDimensions }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Res.</span>
                    </th>
                    <td class="pb-50">
                      {{ mediaResolution }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Mime Type</span>
                    </th>
                    <td class="pb-50">
                      {{ internal.mimeType }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">MD5</span>
                    </th>
                    <td class="pb-50">
                      {{ internal.hash }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <span class="font-weight-bold">Code</span>
                    </th>
                    <td class="pb-50">
                      {{ internal.code }}
                    </td>
                  </tr>
                </table>
              </div>
              <!-- <div class="p-1 border-top bg-light">
                <div class="d-flex align-items-center justify-content-between" v-b-toggle.reference>
                  <h5 class="m-0 text-uppercase text-dark">References</h5>
                  <feather-icon icon="ChevronDownIcon" class="when-closed" />
                  <feather-icon icon="ChevronUpIcon" class="when-open" />
                </div>
              </div>
              <b-collapse id="reference" class="p-1">
                I should start open!
              </b-collapse>
              <div class="p-1 border-top bg-light">
                <div class="d-flex align-items-center justify-content-between" v-b-toggle.tags>
                  <h5 class="m-0 text-uppercase text-dark">Tags</h5>
                  <feather-icon icon="ChevronDownIcon" class="when-closed" />
                  <feather-icon icon="ChevronUpIcon" class="when-open" />
                </div>
              </div>
              <b-collapse id="tags" class="p-1">
                I should start open!
              </b-collapse>
              <div class="p-1 border-top border-bottom bg-light">
                <div class="d-flex align-items-center justify-content-between" v-b-toggle.metadata>
                  <h5 class="m-0 text-uppercase text-dark">Metadata</h5>
                  <feather-icon icon="ChevronDownIcon" class="when-closed" />
                  <feather-icon icon="ChevronUpIcon" class="when-open" />
                </div>
              </div>
              <b-collapse id="metadata" class="p-1">
                I should start open!
              </b-collapse> -->
            </div>
            <div class="footer pr-1 justify-content-end">
              <!-- <b-link variant="link" class="text-danger" style="line-height:15px" size="md" @click="close"> <feather-icon icon="TrashIcon" /> Delete</b-link> -->
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
const filesize = require('filesize')
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import DefaultNotifications from '@/components/Notification/default'
import { mediaLibrary } from '@/api/index'
import fileIcon from '@/assets/file-icons/index.js'

export default {
  props: [],
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      fileIcon,
      visible: false,
      internal: {},
      imageContainerSize: {
        width: 0,
        height: 0,
      },
      imageSize: {
        width: 0,
        height: 0,
      },
    }
  },
  computed: {
    isImage() {
      return this.internal.type == 'image'
    },
    mediaUrl() {
      return `${this.internal.url}`
    },
    createdAt() {
      return this.$moment(this.internal.createdAt).format('MMMM Do, YYYY HH:mm')
    },
    modifiedAt() {
      return this.$moment(this.internal.modifiedAt).format('MMMM Do, YYYY HH:mm')
    },
    mediaSize() {
      if (this.internal.size) return filesize(this.internal.size)
      else return ''
    },
    mediaDimensions() {
      return `${this.internal.width}x${this.internal.height}`
    },
    mediaResolution() {
      return `${this.internal.verticalResolution}dpi`
    },
  },
  methods: {
    edit() {
      this.$emit('image-edit', this.internal)
    },
    open(id) {
      const self = this
      mediaLibrary.getMedia(id).then(res => {
        self.internal = res.data
        self.visible = true
      })
    },
    reset() {
      this.visible = false
      this.internal = {}
    },
    close() {
      this.$refs.mediaInfoModal.hide()
    },
    onCopyLinkSuccess() {
      this.$bus.$emit('notification', { ...DefaultNotifications.infoSuccesful, text: 'Link copied!', position: 'top-right', icon: 'LinkIcon' })
    },
    onCopyLinkError() {
      this.$bus.$emit('notification', { ...DefaultNotifications.saveError, text: 'Link cannot be copied!' })
    },
    onResizeMediaContainer(e) {
      this.imageContainerSize.width = e.detail.width
      this.imageContainerSize.height = e.detail.height

      if (this.internal.type == 'image') {
        let imageHeight = this.internal.height
        let imageWidth = this.internal.width
        let newImageHeight = 0
        let newImageWidth = 0

        if (imageHeight > imageWidth || imageHeight == imageWidth) {
          if (e.detail.height > imageHeight) {
            newImageHeight = imageHeight
          } else {
            newImageHeight = e.detail.height
          }

          newImageWidth = (imageWidth * newImageHeight) / imageHeight
        } else if (imageWidth > imageHeight) {
          if (e.detail.width > imageWidth) {
            newImageWidth = imageWidth
          } else {
            newImageWidth = e.detail.width
          }
          newImageHeight = (imageHeight * newImageWidth) / imageWidth
        }

        this.imageSize.height = newImageHeight
        this.imageSize.width = newImageWidth
      }
    },
  },
}
</script>
<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
