<template>
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h3 class="pr-1 mb-0 pb-0 page-title">
        {{ title }}
      </h3>
      <small class="font-weight-normal mt-0 p-0" v-if="subtitle">{{ subtitle }}</small>
    </div>
    <div class="ml-1">
      <portal-target name="page-header-after-title" slim />
    </div>
  </div>
</template>
<script>
export default {
  props: ['icon', 'title', 'section', 'subtitle'],
}
</script>
<style lang="scss">
.page-title {
  line-height: 1.4rem;
}
</style>
